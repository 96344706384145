import './CoursesClass.css';
import { useContext, useEffect, useState } from 'react';
import ClassDetailsCards from '../../features/CoursesClass/ClassDetailsCards/ClassDetailsCards';
import RateClassBanner from '../../features/CoursesClass/RateClassBanner/RateClassBanner';
import { useParams } from 'react-router';
import axios from 'axios';
import AppContext from '../../contexts/AppContext';
import CoursesClassHeader from '../../features/CoursesClass/CoursesClassHeader/CoursesClassHeader';
import CourseClassHeaderNavigation from "../../components/CourseClassHeaderNavigation/CourseClassHeaderNavigation";
import ClassNotes from "../../features/CoursesClass/ClassNotes/ClassNotes";
import CoursesClassSkeleton from '../../features/CoursesClass/CoursesClassSkeleton/CoursesClassSkeleton';
import AuthContext from "../../contexts/AuthContext";
import MainLayout from "../../layouts/MainLayout/MainLayout";
import showNotification from '../../utils/showNotification';
import { ERROR_HANDLER } from '../../utils/error-handler';
import useOnboarding from '../../hooks/useOnboarding';
import { TOUR_STEPS } from '../../utils/tour';
import OnBoardingBtn from '../../components/OnBoardingBtn/OnBoardingBtn';

const CoursesClass = () => {
   const [lessonsData, setLessonsData] = useState(null);
   const { lessonId } = useParams();
   const { currentUser } = useContext(AppContext);
   const { authToken } = useContext(AuthContext);
   const [state, setState] = useState({ isLoading: false, error: null })


   const [runOnboarding, setRunOnboarding] = useState(false)
   const tour = useOnboarding({
      steps: TOUR_STEPS.lesson,
      page: 'lesson',
      disabled: state.isLoading || state.error,
      runAgain: runOnboarding,
      lastBtnFunction: () => setRunOnboarding(false)
   })

   useEffect(() => {
      const fetchCourseData = async () => {
         setState(prevState => ({ ...prevState, isLoading: true }))

         try {
            const response = await axios.get(
               `${process.env.REACT_APP_BACKEND_SERVER}campus/class/${lessonId}`,
               {
                  headers: {
                     "Content-Type": "application/json",
                     "Authorization": "Bearer " + authToken,
                  }
               }
            );
            setLessonsData(response.data);
         } catch (error) {
            showNotification({
               color: "red",
               status: "error",
               title: "Ha ocurrido un problema cargar información sobre la clase.",
               message: `Detalle: ${ERROR_HANDLER[error.request.status] || ERROR_HANDLER.defaultError}`,
               autoClose: 12000
            })
            setState(prevState => ({ ...prevState, error: 'Lo siento, ha ocurrido un error.' }))
            console.error("Error fetching lessons data:", error);
         } finally {
            setState(prevState => ({ ...prevState, isLoading: false }))
         }
      };
      fetchCourseData();
   }, [lessonId, currentUser._id]);

   return (
      <>
         <OnBoardingBtn onClick={() => setRunOnboarding(true)} />

         <MainLayout>
            <div className="coursesClass-wrapper">
               {tour}
               {state.error &&
                  <div className='CoursesClass-error-container'>
                     <CourseClassHeaderNavigation />
                     <p>{state.error}</p>
                  </div>
               }
               {state.isLoading && < CoursesClassSkeleton />}
               {(lessonsData && !state.error && !state.isLoading) &&
                  <>
                     <section className="coursesClass-nav-section">
                        <CourseClassHeaderNavigation />
                     </section>
                     <section className="coursesClass-container">
                        <CoursesClassHeader lessonId={lessonId} zoomCode={lessonsData.zoomCode} title={lessonsData.name} date={lessonsData.initDate} state={lessonsData.state} roomURL={lessonsData.roomUrl} />
                        {lessonsData.state === 'Closed' && !lessonsData.userHasRated && <RateClassBanner lessonId={lessonId} />}
                        <ClassDetailsCards teachers={lessonsData.teachers} date={lessonsData.initDate} />
                        <ClassNotes notes={lessonsData.notes} />
                     </section>
                  </>
               }
            </div>
         </MainLayout>
      </>
   );
};
export default CoursesClass;
