import React from "react";
import { Button } from "@mantine/core";
import "./LessonCard.css";
import moment from "moment/moment";
import { useNavigate } from "react-router";

const LessonCard = ({ lesson }) => {
   const navigate = useNavigate();

   return (
      <div
         onClick={() => navigate(`/campus/clase/${lesson.data._id}`)}
         className="coursesCards-father-div"
         key={lesson.data._id}
      >
         <div className="coursesCards-div-date">
            <span className="coursesCards-text-span-date">Clase</span>
            <span className="coursesCards-span-date">
               {moment(lesson.data.initDate).format("DD/MM")}
            </span>
         </div>
         <div className="coursesCards-description-color">
            <p className="coursesCards-description">{lesson.data.name}</p>
            <div className="coursesCards-buttons-div">
               <Button
                  fw="normal"
                  color="var(--color-secondary-dark)"
                  onClick={() => navigate(`/campus/clase/${lesson.data._id}`)}
               >
                  Ver Clase
               </Button>
            </div>
         </div>
      </div>
   );
};

export default LessonCard;
