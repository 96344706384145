import "./Login.css";
import useLoginPage from "../../hooks/useLoginPage";
import useUserLocation from "../../hooks/useUserLocation";
import LoginHeader from "../../features/Login/LoginHeader/LoginHeader";
import AvalCarousel from "../../components/AvalCarousel/AvalCarousel";
import { Button, Modal, Stack } from "@mantine/core";
import { IconNews, IconExclamationCircle, IconHandClick } from "@tabler/icons-react";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { openUrl } from "../../utils/OpenUrl";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import GlassCard from "../../components/GlassCard/GlassCard";
import LoginDecorativeCircles from "../../features/Login/LoginDecorativeCircles/LoginDecorativeCircles";
import LoginStaffSkeleton from "../../features/Login/LoginStaffSkeleton/LoginStaffSkeleton";

export default function Login() {
   const {
      acaUnits,
      state,
      staff,
      avals,
      isNotBrowserRecommended,
      officialUSD,
   } = useLoginPage();

   // const { isUSD } = useUserLocation();
   const isUSD = true;

   const location = useLocation();
   const message =
      location.search && new URLSearchParams(location.search).get("message");
   const [opened, setOpened] = useState(message != null);

   const close = () => setOpened(false);

   const handleNavigate = () => {
      openUrl("https://forvet.org/contacto")
   };

   return (
      <main className="LoginPage-container">
         <div className="LoginPage--component">

            <LoginDecorativeCircles />

            <div className="LoginPage-wrapper">
               <LoginHeader
                  acaUnits={acaUnits}
                  officialUSD={officialUSD}
                  isUSD={isUSD}
               />

               <section className="LoginPage-hero-container">
                  <div className="LoginPage-hero-wrapper">


                     <div className="LoginPage-hero-content">
                      
                        <Stack gap={0}>
                           <h1 className="LoginPage-hero-title">
                              <strong>Potenciá</strong> tus conocimientos <br /> y marcá la <strong>diferencia.</strong>
                           </h1>
                        </Stack>

                        <p className='LoginPage-hero-slogan'>
                           <strong>Juntos,</strong> redefiniendo el <strong>futuro</strong> de la profesión.
                        </p>

                        <a
                           className="LoginPage-hero-link--desktop"
                           href='https://forvet.org/blog'
                           rel="noreferrer"
                           target="_blank"
                        >
                           <span>Nuestro blog</span>
                           <IconNews/>
                        </a>

                        <a
                           className="LoginPage-hero-link--mobile"
                           href='https://forvet.org/cursos'
                           rel="noreferrer"
                           target="_blank"
                        >
                           <span>¿Querés ver toda la oferta académica?</span>
                           <IconHandClick/>
                        </a>
                     </div>
                  </div>
               </section>

               {isNotBrowserRecommended && (
                  <section className="LoginPage-browser-warning-container">
                     <IconExclamationCircle size={40} color="#2C4B58" />
                     <p className="LoginPage-browser-warning--text">
                        Estas usando el navegador{" "}
                        <strong>{isNotBrowserRecommended}</strong>{" "}
                     </p>
                     <p className="LoginPage-browser-warning--text">
                        Para una mejor experiencia te recomendamos que utilices{" "}
                        <strong>Chrome</strong> o <strong>Edge</strong> o puedes
                        habilitar las <strong>cookies de terceros</strong>
                     </p>
                  </section>
               )}
            </div>

            <footer className="LoginPage-footer-container">
               {(!state.error && state.isLoading) && <LoginStaffSkeleton />}
               <Splide
                  options={{
                     type: 'loop',
                     drag: 'free',
                     focus: 'center',
                     autoWidth: true,
                     gap: 40,
                     arrows: false,
                     pagination: false,
                     autoScroll: {
                        speed: .8,
                     },
                  }}
                  extensions={{ AutoScroll }}
               >
                  {(!state.error && !state.isLoading && staff) && staff?.map((item, idx) => (
                     <SplideSlide key={item._id || idx}>
                        <GlassCard item={item} />
                     </SplideSlide>
                  ))}
               </Splide>

               <AvalCarousel itemsToMap={avals} state={state} />
            </footer>
         </div>

         {message && (
            <Modal
               classNames={{
                  title: "loginPage-modal-titleHeader",
                  body: "loginPage-modal-body",
                  header: "loginPage-modal-headerStyling",
               }}
               opened={opened}
               onClose={close}
               title="Hay irregularidades en tu cuenta"
            >
               <div className="loginPage-modal-layout">
                  <img
                     alt="perro"
                     className="loginPage-modal-img"
                     src="https://ugokawaii.com/wp-content/uploads/2022/06/dog-distress.gif"
                  />
                  {message}
                  <Button onClick={handleNavigate} fw="normal">
                     Contáctanos
                  </Button>
               </div>
            </Modal>
         )}
      </main>
   );
}
