import './Forum.css'
import MainLayout from '../../layouts/MainLayout/MainLayout'
import ForumPageHeader from '../../features/Forum/ForumPageHeader/ForumPageHeader'
import ForumPageContent from '../../features/Forum/ForumPageContent/ForumPageContent'
import useForum from '../../hooks/useForum'
import ForumTermsAndConditionsModal
   from '../../features/Forum/ForumTermsAndConditionsModal/ForumTermsAndConditionsModal'
import ForumNoAcceptTermsAndConditions
   from '../../features/Forum/ForumNoAcceptTermsAndConditions/ForumNoAcceptTermsAndConditions'
import ForumNewDiscussion from '../../components/Forum/ForumNewDiscussion/ForumNewDiscussion'
import { useContext, useState } from 'react'
import AppContext from '../../contexts/AppContext'
import { NO_AUTHORIZATION_CODE } from '../../utils/forum-rules'
import useOnboarding from '../../hooks/useOnboarding'
import { TOUR_STEPS } from '../../utils/tour'
import OnBoardingBtn from '../../components/OnBoardingBtn/OnBoardingBtn'
import useWindowDimensions from '../../hooks/useWindowDimensions'

export default function Forum() {
   const { width } = useWindowDimensions()
   const isTablet = width < 1000;

   const { discussionThemes } = useContext(AppContext)
   const {
      isDecrecent,
      allDiscussionTopics,
      handleToggleFilter,
      state,
      getDiscussionThemes,
      isModal,
      setIsModal,
      reRender,
      setReRender,
   } = useForum()


   const [runOnboarding, setRunOnboarding] = useState(false)
   const tour = useOnboarding({
      steps: isTablet ? TOUR_STEPS.foro.mobile : TOUR_STEPS.foro.desktop,
      disabled: state.isLoading || state.error.message || state.error.status === NO_AUTHORIZATION_CODE,
      page: 'foro',
      runAgain: runOnboarding,
      lastBtnFunction: () => setRunOnboarding(false)
   })

   return (
      <>
         <OnBoardingBtn onClick={() => setRunOnboarding(true)} />
         <MainLayout>
            <div className='ForoPage-section-wrapper'>

               {discussionThemes && discussionThemes !== undefined
                  ? < ForumNewDiscussion reRender={reRender} setReRender={setReRender} />
                  : <section className='ForoPage-section-container'>
                     {tour}
                     < ForumPageHeader
                        handleToggleFilter={handleToggleFilter}
                        isDecrecent={isDecrecent}
                        getDiscussionThemes={getDiscussionThemes}
                        state={state}
                     />

                     {/* modal para los terminos y condiciones */}
                     {isModal && < ForumTermsAndConditionsModal isModal={isModal} setIsModal={setIsModal} />}

                     {/* se fija primero si tiene autorizacion, luego el componente maneja los errores dentro */}
                     {(state.error.status === NO_AUTHORIZATION_CODE && !isModal)
                        ? < ForumNoAcceptTermsAndConditions setIsModal={setIsModal} />
                        : < ForumPageContent
                           state={state}
                           data={allDiscussionTopics}
                        />
                     }
                  </section>
               }
            </div>
         </MainLayout>
      </>
   )
}
