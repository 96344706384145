import { useNavigate } from 'react-router';
import './CourseClassHeaderNavigation.css';
import { IconArrowLeft } from '@tabler/icons-react';
import { Button } from '@mantine/core';

const CourseClassHeaderNavigation = ({ btnText = 'Volver a mi curso', title, onNavigate, noButton }) => {
   const navigate = useNavigate();
   const handleClickNavigate = () => {
      if (onNavigate) {
         onNavigate();
      } else {
         navigate(-1);
      }
   };
   return (
      <>
         {!noButton &&
            <div className="coursesClassHeaderNavigation-button-div">
               <Button
                  fw="normal"
                  variant="subtle"
                  color="var(--color-base)"
                  onClick={handleClickNavigate}
               >
                  <span className="coursesClassHeaderNavigation-button-span">
                     {<IconArrowLeft />}
                  </span>
                  {btnText}
               </Button>
            </div>
         }
         {title && <div className="coursesClassHeaderNavigation-classTitle-div">
            <h2 className="coursesClassHeaderNavigation-title-styling">{title}</h2>
         </div>}

      </>
   )
}
export default CourseClassHeaderNavigation