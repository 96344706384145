import './UserProfile.css'
import UserProfileHeader from '../../features/UserProfile/UserProfileHeader/UserProfileHeader'
import UserProfileForm from '../../features/UserProfile/UserProfileForm/UserProfileForm'
import MainLayout from '../../layouts/MainLayout/MainLayout'
import UserProfileCarousel from '../../features/UserProfile/UserProfileCarousel/UserProfileCarousel'
import AppContext from "../../contexts/AppContext";
import {useContext} from "react";


export default function UserProfile() {
    const {currentUser} = useContext(AppContext);

    

    return (
        <MainLayout>
            <section className='UserProfilePage-container'>

                < UserProfileHeader/>

                <div className='UserProfile-content--row'>
                    < UserProfileForm currentUser={currentUser}/>
                    < UserProfileCarousel/>
                </div>
            </section>
        </MainLayout>
    )
}
