import { Splide, SplideSlide } from '@splidejs/react-splide'
import './LoginMegaMenu.css'
import { capitalize } from '../../../utils/capitalize'
import { Button, Flex, Stack } from '@mantine/core'
import { IconChevronRight } from '@tabler/icons-react'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import { calculatePrice } from '../../../utils/calculatePrice'
import { formattedPrice } from '../../../utils/formatted-price'
import { currencyFormat } from '../../../utils/currencyFormat'
import LoginMegaMenuSkeleton from './LoginMegaMenuSkeleton'

export default function LoginMegaMenu({
   isMenu,
   type,
   onMouseEnter,
   onMouseLeave,
   acaUnits,
   officialUSD,
   isUSD
}) {

   const acaUnitsFiltered = acaUnits?.open?.filter(item => (
      item.type?.toLowerCase() === type
   ))

   const { width } = useWindowDimensions()

   const slidePerPage = width <= 1100 ? 2 : 3

   return (
      <div
         onMouseEnter={() => onMouseEnter(type)}
         onMouseLeave={() => onMouseLeave()}
         className={`LoginHeader--Menu ${isMenu ? 'LoginHeader-Menu--active' : ''}`}
      >
         <div className='LoginMegaMenu-carousel-wrapper'>
            {type &&
               <h2 className='LoginMegaMenu--typeTitle'>
                  {`${capitalize(type)}s`}
               </h2>}
            <Splide
               options={{
                  rewind: true,
                  pagination: false,
                  perPage: slidePerPage,
                  perMove: 1,
                  width: '100%',
                  padding: 32,
                  gap: 16
               }}
            >
               {!acaUnitsFiltered && <LoginMegaMenuSkeleton/>}
               {acaUnitsFiltered && acaUnitsFiltered?.map(item => (
                  <SplideSlide
                     key={item._id}
                     className='LoginMegaMenu-slide-container'
                  >
                     
                     <article className='LoginMegaMenu--cardSlide'>
                        <div className='LoginMegaMenu-cardSlide--title-wrapper'>
                           <h3 className='LoginMegaMenu-cardSlide--title'>{item.comercialInformation.name}</h3>
                        </div>
                        <div className='LoginMegaMenu-cardSlide--content'>
                           {item.comercialInformation.initialDate &&
                              <Flex align='flex-start'>
                                 <IconChevronRight color='var(--color-text-primary)' />
                                 <Stack align='flex-start' gap={0}>
                                    <h4 className='LoginMegaMenu-CardSlide-label'>Comienza el:</h4>
                                    <span className='LoginMegaMenu-CardSlide--text'>{item.comercialInformation.initialDate}</span>
                                 </Stack>
                              </Flex>
                           }
                           {item.comercialInformation.endDate &&
                              <Flex align='flex-start'>
                                 <IconChevronRight color='var(--color-text-primary)' />
                                 <Stack align='flex-start' gap={0}>
                                    <h4 className='LoginMegaMenu-CardSlide-label'>Termina el:</h4>
                                    <span className='LoginMegaMenu-CardSlide--text'>{item.comercialInformation.endDate}</span>
                                 </Stack>
                              </Flex>
                           }
                           {(item.comercialInformation.classDays && item.comercialInformation.classHour) &&
                              <Flex align='flex-start'>
                                 <IconChevronRight color='var(--color-text-primary)' />
                                 <Stack align='flex-start' gap={0}>
                                    <h4 className='LoginMegaMenu-CardSlide-label'>Dias y horarios:</h4>
                                    <span className='LoginMegaMenu-CardSlide--text'>{item.comercialInformation.classDays} A LAS {item.comercialInformation.classHour}</span>
                                 </Stack>
                              </Flex>
                           }
                           {item.specialties &&
                              <Flex align='flex-start'>
                                 <IconChevronRight color='var(--color-text-primary)' />
                                 <Stack align='flex-start' gap={0}>
                                    <h4 className='LoginMegaMenu-CardSlide-label'>Especialidad:</h4>
                                    <span className='LoginMegaMenu-CardSlide--text'>
                                       {item.specialties.toUpperCase()}
                                    </span>
                                 </Stack>
                              </Flex>
                           }
                           {item.comercialInformation.price &&
                              <Stack gap={0}>
                                 <h4 className='LoginMegaMenu-CardSlide--price'>Precio:</h4>
                                 <span className='LoginMegaMenu-CardSlide--textPrice'>
                                    {formattedPrice(
                                       currencyFormat(
                                          calculatePrice(
                                             item.comercialInformation.price,
                                             officialUSD,
                                             isUSD,
                                             item.type
                                          )
                                       ), isUSD
                                    )}
                                 </span>
                              </Stack>
                           }
                        </div>
                        <div className='LoginMegaMenu-button-container'>
                           <Button
                              target='_blank'
                              href={`https://forvet.org/curso/${item._id}`}
                              component='a'
                              radius='md'
                              color='var(--color-text-secondary)'
                              fullWidth
                              mt={32}
                           >
                              Ver más</Button>
                        </div>
                     </article>
                  </SplideSlide>
               ))}
            </Splide>
         </div>
      </div >
   )
}
