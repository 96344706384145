import "./CoursesMain.css";
import CoursesHeader from "./CoursesHeader/CoursesHeader";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import CoursesSkeleton from "./CoursesSkeleton/CoursesSkeleton";
import AuthContext from "../../contexts/AuthContext";
import LessonCard from "../../components/CoursesCards/LessonCard";
import ExamCard from "../../components/CoursesCards/ExamCard";
import ReExamCard from "../../components/CoursesCards/ReExamCard";
import showNotification from "../../utils/showNotification";
import { ERROR_HANDLER } from "../../utils/error-handler";
import NoContentMessage from "../../components/NoContentMessage/NoContentMessage";
import NoLessonsGIF from "../../assets/Others/noLessons.gif";
import { MaterialsTable } from "./MaterialsTable/MaterialsTable";
import { Button } from "@mantine/core";
import { IconBooks, IconSchool } from "@tabler/icons-react";
import useOnboarding from "../../hooks/useOnboarding";
import { TOUR_STEPS } from "../../utils/tour";
import OnBoardingBtn from "../../components/OnBoardingBtn/OnBoardingBtn";

const CoursesMain = () => {
   const [courseData, setCourseData] = useState(null);
   const [loading, setLoading] = useState({ isLoading: false, error: null });
   const { courseId } = useParams();
   const { authToken } = useContext(AuthContext);
   const [allCourseMaterials, setAllCourseMaterials] = useState([]);
   const [isMaterial, setIsMaterial] = useState(false)


   const [runOnboarding, setRunOnboarding] = useState(false)
   const tour = useOnboarding({
      steps: TOUR_STEPS.course,
      page: 'course',
      disabled: loading.isLoading || loading.error,
      runAgain: runOnboarding,
      lastBtnFunction: () => setRunOnboarding(false)
   })

   const fetchCourseData = async () => {
      setLoading((prevState) => ({ ...prevState, isLoading: true }));
      try {
         // Obtener data del curso
         const { data: courseInfoData } = await axios.get(
            `${process.env.REACT_APP_BACKEND_SERVER}campus/course/${courseId}`,
            {
               headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + authToken,
               },
            }
         );
         setCourseData(courseInfoData);

         // obtener todos los materiales del curso
         const { data: allCourseMaterialsData } = await axios.get(
            `${process.env.REACT_APP_BACKEND_SERVER}campus/class/${courseId}/allMaterials`,
            {
               headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + authToken,
               },
            }
         )
         setAllCourseMaterials(allCourseMaterialsData)
      } catch (error) {
         console.error("Error fetching course data:", error);
         setLoading(prevState => ({ ...prevState, error: 'Lo siento, ha ocurrido un error.' }))
         showNotification({
            color: "red",
            status: "error",
            title: "Ha ocurrido un problema traer información sobre el curso.",
            message: `Detalle: ${ERROR_HANDLER[error.request.status] || ERROR_HANDLER.defaultError}`,
            autoClose: 12000
         })
      } finally {
         setLoading((prevState) => ({ ...prevState, isLoading: false }));
      }
   };

   useEffect(() => {
      fetchCourseData();
   }, [courseId]);

   if (loading.isLoading) {
      return <CoursesSkeleton />;
   }



   if (courseData && !loading.error && !loading.isLoading) {
      return (
         <>
            <OnBoardingBtn onClick={() => setRunOnboarding(true)} />

            <div className="coursesMain-wrapper">
               {tour}
               <section>
                  <CoursesHeader title={courseData.name} image={courseData.image} />
               </section>

               <section className="coursesMain-material-button-container">
                  <Button id="CoursesMain-toggle-button" onClick={() => setIsMaterial(!isMaterial)} color='var(--color-tertiary)'>{isMaterial
                     ? <>
                        <span className="coursesMain-material--buttonText">
                           Ver clases
                        </span>
                        <IconSchool size={24} />
                     </>

                     : <>
                        <span className="coursesMain-material--buttonText">
                           Ver material
                        </span>
                        <IconBooks size={24} />
                     </>
                  }
                  </Button>
               </section>

               {isMaterial
                  ? <section className="coursesMain-material-table-container">
                     < MaterialsTable data={allCourseMaterials.general} height='max-content'
                        tableTitle='Materiales generales' />
                     < MaterialsTable data={allCourseMaterials.lessons} height='max-content'
                        tableTitle='Materiales por clase' />
                  </section>
                  : <section className="coursesMain-container">
                     {courseData.events.length === 0 &&
                        <NoContentMessage
                           gif={NoLessonsGIF}
                           title="De momento no hay clases disponibles."
                           message="¡Nuestro equipo está trabajando para que accedas a tu contenido lo más pronto posible!"
                        />
                     }
                     {courseData.events.map((lesson) => {
                        switch (lesson.type) {
                           case "lessons":
                              return <LessonCard key={lesson.id} lesson={lesson} />;
                           case "exams":
                              return <ExamCard key={lesson.id} title={courseData.name} lesson={lesson} />;
                           case "recoveryExam":
                              return <ReExamCard key={lesson.id} title={courseData.name} lesson={lesson} />;
                           //TODO: Remove "reExam" when no longer needed
                           case "reExam":
                              return <ReExamCard key={lesson.id} title={courseData.name} lesson={lesson} />;
                           default:
                              return null;
                        }
                     })}
                  </section>}
            </div>
         </>
      );
   }

   return (
      <p className="coursesMain-errorText">
         Lo sentimos, hubo un error al cargar la página. Intenta mas tarde.
      </p>
   );
};

export default CoursesMain;
