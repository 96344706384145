import './RegisterPageForm.css';
import {
   Autocomplete,
   Button,
   Loader,
   PasswordInput,
   Select,
   Stack,
   TextInput,
   Grid,
   Modal,
   Flex
} from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { COUNTRY_LIST } from '../../../utils/country-list-registerPage';
import { useContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { GENDER_LIST } from '../../../utils/gender-list';
import { VALIDATIONS } from '../../../utils/validations-functions';
import useProfileForm from '../../../hooks/useProfileForm';
import TermsAndConditions from "../../../components/Terms and Conditions Register/TermsAndConditions";
import moment from 'moment';
import SelectPreferedPet from '../SelectPreferedPet/SelectPreferedPet';
import { USER_DEFAULT_VALUES } from '../../../utils/user-default-values';
import PasswordChecker from "../../../components/PasswordChecker/PasswordChecker";
import AppContext from '../../../contexts/AppContext';
import showNotification from '../../../utils/showNotification';
import { IconHelp } from '@tabler/icons-react';

const DNI_EXAMPLES_BY_COUNTRIES = [
   { label: 'Argentina', color: '#75AADB', example: '20345678' },
   { label: 'Bolivia', color: '#FFC72C', example: '45678901' },
   { label: 'Brasil', color: '#6DA544', example: '123.456.789-09' },
   { label: 'Chile', color: '#D52B1E', example: '12.345.678-9' },
   { label: 'Colombia', color: '#FCD116', example: '1.234.567.890' },
   { label: 'Ecuador', color: '#FCD116', example: '1712345678' },
   { label: 'Paraguay', color: '#0038A8', example: '4567890' },
   { label: 'Perú', color: '#D91023', example: '87654321' },
   { label: 'Uruguay', color: '#4196E1', example: '1.234.567-8' },
   { label: 'Venezuela', color: '#FCD116', example: 'V-12345678' },
   { label: 'Estados Unidos', color: '#3C3B6E', example: '123-45-6789' },
   { label: 'México', color: '#006847', example: 'ABCD123456HXXMN0' }
];

function isInvalidOrEmptyDate(date) {
   if (!date) return true;
   const parsedDate = new Date(date);
   return isNaN(parsedDate.getTime());
}

export default function RegisterPageForm() {
   const { user } = useAuth0();
   const { currentUser } = useContext(AppContext);
   const [isChecked, setIsChecked] = useState(false);
   const [dateValue, setDateValue] = useState(currentUser?.birthDate ?? null);
   const [displayChangePassword, setDisplayChangePassword] = useState(false);
   const [displayPass, setDisplayPass] = useState(true);

   // valores iniciales
   let initialFormValues = {
      profilePicture: currentUser?.profilePicture ?? user?.picture ?? USER_DEFAULT_VALUES.profilePicture,
      email: user.email ?? '',
      nationality: currentUser?.nationality ?? '',
      birthDate: currentUser?.birthDate ?? null,
      gender: currentUser?.gender ?? '',
      city: currentUser?.city ?? '',
      animalType: currentUser?.animalType ?? 'dog',
      firstPassword: '',
      confirmPassword: '',
      dni: currentUser?.dni ?? '',
   };

   // validaciones iniciales
   let formValidations = {
      nationality: VALIDATIONS.nationality,
      birthDate: VALIDATIONS.birthDate,
      gender: VALIDATIONS.gender,
      city: VALIDATIONS.city,
      firstPassword: VALIDATIONS.firstPassword,
      confirmPassword: VALIDATIONS.confirmPassword,
      dni: VALIDATIONS.dni
   };

   const IS_NEW_USER = !currentUser?.city;

   const {
      form,
      profilePicture,
      handleFileChange,
      handleSubmit,
      state,
      animalType,
      setAnimalType
   } = useProfileForm(initialFormValues, formValidations, IS_NEW_USER);

   useEffect(() => {
      if (!IS_NEW_USER && !currentUser?.dni) {
         setDisplayPass(false);
         showNotification({
            color: "var(--color-yellow)",
            status: "warning",
            title: "Necesitamos que completes mas datos.",
            message: `Hemos agregado el numero de identificación a nuestra base de datos para mejorar tu experiencia, agradecemos tu colaboración.`,
            autoClose: 12000
         });
      }
   }, []);

   const [dniExamplesModal, setDniExamplesModal] = useState(false)

   return (
      <form className='RegisterPage-form-container' onSubmit={handleSubmit}>

         <div className='RegisterPage-avatar-group'>

            <div className='RegisterPage-avatar-container'>
               <img className='RegisterPage-avatar-image' src={profilePicture} alt='avatar del usuario Nombre' />
            </div>

            <input
               className="RegisterPage-avatar--input"
               type="file"
               accept="image/png,image/jpeg"
               onChange={handleFileChange}
            />
         </div>

         <Grid style={{ width: "100%" }}>
            {initialFormValues.email === '' && (
               <Grid.Col span={12}>
                  <TextInput
                     label="Email:"
                     {...form.getInputProps("email")}
                     w={'100%'}
                     disabled
                  />
               </Grid.Col>
            )}
            {(initialFormValues.dni === '' &&
               <Grid.Col span={12}>
                  <TextInput
                     label='Número de identificación'
                     {...form.getInputProps('dni')}
                     w={'100%'}
                     name='dni'
                     autoComplete='off'
                  />
                  <Button onClick={() => setDniExamplesModal(true)} mt={8} size='compact-xs' variant='transparent'>
                     <IconHelp color='var(--color-text-primary)' />
                     <span style={{ color: 'var(--color-text-primary)', textDecoration: 'underline' }}>Ver ejemplos de como ingresar el DNI</span>
                  </Button>

                  <Modal
                     onClose={() => setDniExamplesModal(false)}
                     closeOnEscape
                     opened={dniExamplesModal}
                  >
                     <h3 className='dniExamplesModal--title'>Ejemplos según países</h3>


                     <Stack>
                        {DNI_EXAMPLES_BY_COUNTRIES.map(item => (
                           <Flex key={item.label} align='center' justify='start' gap={8}>
                              <div className='dniExamplesModal--countryColor' style={{ backgroundColor: item.color }}></div>
                              <h4 className='dniExamplesModal--countryName'>{item.label}:</h4>
                              <span className='dniExamplesModal--countryDni'>{item.example}</span>
                           </Flex>
                        ))}
                     </Stack>
                     <Flex w='full' justify='center'><Button mt={16} mb={8} variant='outline' onClick={() => setDniExamplesModal(false)}>Cerrar</Button></Flex>
                  </Modal>
               </Grid.Col>
            )}
            {isInvalidOrEmptyDate(initialFormValues.birthDate) && (
               <Grid.Col span={12}>
                  <DateInput
                     label="Fecha de nacimiento"
                     dateParser={(dateString) => moment(dateString, 'DD/MM/YYYY').toDate()}
                     defaultLevel="decade"
                     allowFreeInput
                     locale="es"
                     placeholder="dd/mm/yyyy"
                     inputFormat="DD/MM/YYYY"
                     valueFormat="DD/MM/YYYY"
                     // value={dateValue}
                     onChange={(e) => setDateValue(moment(e, 'DD/MM/YYYY').toDate())}
                     onBlur={() => {
                        form.setFieldValue('birthDate', dateValue)
                     }}
                  />
               </Grid.Col>
            )}
            {initialFormValues.gender === '' && (
               <Grid.Col span={12}>
                  <Select
                     label='Género:'
                     data={GENDER_LIST}
                     {...form.getInputProps('gender')}
                     autoComplete='off'
                  />
               </Grid.Col>
            )}
            {initialFormValues.nationality === '' && (
               <Grid.Col span={12}>
                  <Autocomplete
                     data={COUNTRY_LIST}
                     label='Nacionalidad:'
                     {...form.getInputProps('nationality')}
                     autoComplete='off'
                  />
               </Grid.Col>
            )}
            {initialFormValues.city === '' && (
               <Grid.Col span={12}>
                  <TextInput
                     label='Ciudad:'
                     {...form.getInputProps('city')}
                     autoComplete='off'
                  />
               </Grid.Col>
            )}
            <Grid.Col span={12}>
               <SelectPreferedPet
                  setFieldValue={form.setFieldValue}
                  animalType={animalType}
                  setAnimalType={setAnimalType}
               />
            </Grid.Col>

            {displayPass && initialFormValues.firstPassword === '' && (
               <>
                  <Grid.Col span={12}>
                     <p className='RegisterPage-passwords-text'>Te recomendamos que cambies tu contraseña</p>
                  </Grid.Col>
                  <Grid.Col span={12}>
                     <p
                        className='RegisterPage-passwords-text-clickable'
                        onClick={() => setDisplayChangePassword(!displayChangePassword)}
                     >
                        Cambiar contraseña
                     </p>
                  </Grid.Col>
                  {displayChangePassword && (
                     <>
                        <Grid.Col span={12}>
                           <PasswordInput
                              label='Ingresá tu nueva contraseña:'
                              classNames={{ input: 'RegisterPageForm-password--input' }}
                              name='firstPassword'
                              placeholder=''
                              {...form.getInputProps('firstPassword')}
                              autoComplete='off'
                           />
                        </Grid.Col>
                        <Grid.Col span={12}>
                           <PasswordInput
                              label='Repetí tu contraseña:'
                              classNames={{ input: 'RegisterPageForm-password--input' }}
                              name='confirmPassword'
                              placeholder=''
                              {...form.getInputProps('confirmPassword')}
                              autoComplete='off'
                           />
                        </Grid.Col>
                        <Grid.Col span={12}>
                           <PasswordChecker password={form.values.firstPassword} />
                        </Grid.Col>
                     </>
                  )}
               </>
            )}

            <Grid.Col span={12}>
               <TermsAndConditions setChecked={setIsChecked} checked={isChecked} />
            </Grid.Col>
         </Grid>

         <Stack>
            <Button
               type="submit"
               w={{ base: "100%", sm: "50%" }}
               m={"auto"}
               disabled={state.isLoading || !isChecked}
            >
               {state.isLoading ? (
                  <Loader color={"#fff"} size={20} />
               ) : (
                  "Completar registro"
               )}
            </Button>
         </Stack>

         {state.error && (
            <p
               style={{
                  fontSize: "var(--fs-body)",
                  color: "var(--color-red)",
                  textAlign: "center",
               }}
            >
               {state.error}
            </p>
         )}
      </form>
   );
}
