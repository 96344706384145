import { Button } from '@mantine/core'
import { IconMoodPuzzled } from '@tabler/icons-react'
import React from 'react'

export default function OnBoardingBtn({onClick}) {
  return (
      <Button variant='outline' color='var(--color-tertiary)' style={{zIndex: 10}} pos='fixed' bottom={10} right={10} onClick={onClick}>
         <IconMoodPuzzled/>
         <span>Guía</span>
      </Button>
  )
}
