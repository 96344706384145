import React, {useState} from 'react';
import ConfettiExplosion from 'react-confetti-explosion';
import './ConfettiParticles.css';

export const confettiProps = {
    force: 0.8,
    duration: 5000,
    zIndex: 1000,
    particleCount: 100,
    width: 1000,
    colors: ['#355562', '#77BDE0', '#DF3576'],
};

function ConfettiParticles({ children }) {
    const [isExploding, setIsExploding] = useState(false);

    const onShoot = () => {
        if (isExploding) return;
        setIsExploding(true);
        setTimeout(() => {
            setIsExploding(false);
        }, 5000)
    };

    return (
        <div className="confettiParticles__container" onClick={onShoot}>
            {isExploding && <ConfettiExplosion {...confettiProps}/>}
            {children}
        </div>
    );
}

export default ConfettiParticles;