import './ForumPageContent.css'
import ForumCard from '../ForumCard/ForumCard'
import { IconMoodWrrr } from '@tabler/icons-react'
import ForumSkeletonCard from '../ForumSkeletonCard/ForumSkeletonCard'
import axios from 'axios'
import showNotification from '../../../utils/showNotification'
import { useContext } from 'react'
import AuthContext from '../../../contexts/AuthContext'
import { ERROR_HANDLER } from '../../../utils/error-handler'
import NoContentGIF from "../../../assets/Others/noContent.gif"
import NoContentMessage from '../../../components/NoContentMessage/NoContentMessage'

export default function ForumPageContent({ data, state }) {
   const { authToken } = useContext(AuthContext);

   const handleReportSubmit = async (replyId) => {
      try {
         await axios.put(
            `${process.env.REACT_APP_BACKEND_SERVER}campus/users/reportDiscussion`,
            { idDiscussion: replyId },
            {
               headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + authToken,
               },
            }
         );
         showNotification({
            color: "green",
            status: "success",
            title: "El tema fue denunciado exitosamente.",
            message: `Revisaremos la situación y tomaremos la decisión correspondiente.`,
         });
      } catch (error) {
         showNotification({
            color: "red",
            status: "error",
            title: "Ha ocurrido un problema al enviar tu denuncia.",
            message: `Detalle: ${ERROR_HANDLER[error.request.status] || ERROR_HANDLER.defaultError}`,
            autoClose: 12000
         });
         console.error("Error sending report:", error);
      }
   };

   return (
      <section className='ForoPageContent-container' style={{ display: 'flex', flexDirection: 'column', gap: '50px' }}>

         {!state.isLoading && state.error.message &&
            <div className='ForoPageContent-error-container'>
               < IconMoodWrrr color='var(--color-red)' size={150} />
               <p className='ForoPageContent-error--text'>{state.error.message}</p>
            </div>
         }

         {state.isLoading && <>
            < ForumSkeletonCard variant={1} />
            < ForumSkeletonCard variant={3} />
            < ForumSkeletonCard variant={2} />
         </>}

         {(!state.error.status && data.length !== 0) &&
            data?.map(item => (
               < ForumCard
                  key={item._id}
                  title={item.title}
                  text={item.discussionInit}
                  ForumPageContent={true}
                  tag={item.name}
                  username={`${item.user.name ?? ''} ${item.user.lastname ?? ''}`}
                  commentAmount={item?.replies.length ?? '0'}
                  report={() => {
                     handleReportSubmit(item._id);
                  }}
                  profilePicture={item.user.profilePicture}
                  discussionId={item._id}
               />
            ))}

         {(!state.error.message && !state.isLoading && data.length === 0) &&
            <NoContentMessage
               gif={NoContentGIF}
               title="Parece que aún no hay discusiones aquí, pero todos empezamos desde cero."
               message="¿Por qué no ser el primero en iniciar una conversación interesante? ¡Es tu oportunidad de compartir tus ideas, hacer preguntas o simplemente conectarte con la comunidad! ¡Adelante, no seas tímido, esperamos ansiosos tu primer mensaje!"
            />
         }

      </section>
   )
}
