import {Routes, Route} from "react-router-dom";
import Dashboard from "../pages/Dashboard/Dashboard";
import Courses from "../pages/Courses/Courses";
import CoursesClass from "../pages/CoursesClass/CoursesClass";
import UserProfile from "../pages/UserProfile/UserProfile";
import Certificates from "../pages/Certificates/Certificates";
import PrevExamPage from "../pages/PrevExamPage/PrevExamPage";
import Exams from "../pages/Exams/Exams";
import Materials from "../pages/Materials/Materials";
import {withAppContextProvider, withAppContextReady} from "../contexts/AppContext";
import Forum from "../pages/Forum/Forum";
import ForumDiscussion from "../features/Forum/ForumDiscussion/ForumDiscussion";
import Help from "../pages/Help/Help";
import Opportunities from "../pages/Opportunities/Opportunities";

import OwnCourses from "../pages/OwnCourses/OwnCourses";
import RegisterPage from "../pages/RegisterPage/RegisterPage";

const CampusRoutes = () => {
    return (
        <Routes>
            <Route exact path="/dashboard" element={<Dashboard/>}/>
            <Route exact path="/mis-cursos" element={<OwnCourses/>}/>
            <Route exact path="/curso/:courseId" element={<Courses/>}/>
            <Route exact path="/clase/:lessonId" element={<CoursesClass/>}/>
            <Route exact path="/clase/:lessonId/material" element={<Materials/>}/>
            <Route exact path="/certificaciones" element={<Certificates/>}/>
            <Route exact path="/perfil" element={<UserProfile/>}/>
            <Route exact path="/antes-de-evaluar/:courseName/:examId" element={<PrevExamPage/>}/>
            <Route exact path="/examen/:id" element={<Exams/>}/>
            <Route exact path="/curso/:lessonId/material" element={<Materials/>}/>
            <Route exact path="/foro" element={<Forum/>}/>
            <Route exact path="/foro-debate/:discussionId" element={<ForumDiscussion/>}/>
            <Route exact path="/ayuda" element={<Help/>}/>
            <Route exact path="/oportunidades" element={<Opportunities/>}/>
            <Route exact path="/registro" element={<RegisterPage/>}/>
        </Routes>
    );
};
export default withAppContextProvider(withAppContextReady(CampusRoutes));
