import { Button, Group, Textarea } from "@mantine/core";
import MainLayout from "../../../layouts/MainLayout/MainLayout";
import ForumCard from "../ForumCard/ForumCard";
import "./ForumDiscussion.css";
import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import AuthContext from "../../../contexts/AuthContext";
import { TOUR_STEPS } from "../../../utils/tour";
import showNotification from "../../../utils/showNotification";
import DecorativeEmptyCircle from "../../../components/DecorativeEmptyCircle/DecorativeEmptyCircle";
import CourseClassHeaderNavigation from "../../../components/CourseClassHeaderNavigation/CourseClassHeaderNavigation";
import { useParams } from "react-router-dom";
import ForumDiscussionSkeleton from "./ForumDiscussionSkeleton";
import io from "socket.io-client";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import useOnboarding from "../../../hooks/useOnboarding";
import { ERROR_HANDLER } from "../../../utils/error-handler";
import ForumRichText from "../ForumRichText/ForumRichText";
import OnBoardingBtn from "../../../components/OnBoardingBtn/OnBoardingBtn";

const ForumDiscussion = () => {
   const [getDiscussion, setGetDiscussion] = useState();
   const [comment, setComment] = useState("");
   const { authToken } = useContext(AuthContext);
   const [socket, setSocket] = useState(null);
   const { discussionId } = useParams();
   const [loading, setLoading] = useState(false);
   const [buttonLoading, setButtonLoading] = useState(false);
   const answersEndRef = useRef(null);
   const { width } = useWindowDimensions();
   const isTablet = width <= 1000;
   const [isRichText, setIsRichText] = useState(false)

   const fetchDiscussion = async () => {
      try {
         setLoading(true);
         const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_SERVER}campus/users/discussions/${discussionId}`,
            {
               headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + authToken,
               },
            }
         );
         setGetDiscussion(response.data);
      } catch (error) {
         showNotification({
            color: "red",
            status: "error",
            title: "Ha ocurrido un problema al obtener información sobre la discusión.",
            message: `Detalle: ${ERROR_HANDLER[error.request.status] || ERROR_HANDLER.defaultError}`,
            autoClose: 12000
         })
      } finally {
         setLoading(false);
      }
   };

   useEffect(() => {
      fetchDiscussion();
   }, []);

   const handleCommentChange = (event) => {
      setComment(event.target.value);
   };

   const isCommentEmpty = comment.trim() === "";

   useEffect(() => {
      const newSocket = io(process.env.REACT_APP_SOCKETIO_SERVER, {
         withCredentials: true,
         extraHeaders: {
            "Access-Control-Allow-Origin": process.env.REACT_APP_FRONTEND_SERVER,
         },
      });
      setSocket(newSocket);

      return () => {
         if (newSocket) {
            newSocket.disconnect();
         }
      };
   }, []);

   useEffect(() => {
      if (socket) {
         socket.on("newReply", (newReply) => {
            setGetDiscussion((prevDiscussion) => {
               const updatedReplies = [...prevDiscussion.replies, newReply];
               return {
                  ...prevDiscussion,
                  replies: updatedReplies,
               };
            });
            scrollToBottom();
         });
      }
      return () => {
         if (socket) {
            socket.off("newReply");
         }
      };
   }, [socket]);

   const scrollToBottom = () => {
      if (answersEndRef.current) {
         answersEndRef.current.scrollIntoView({ behavior: "smooth" });
      }
   };

   const handleCommentSubmit = async (content) => {
      try {
         setButtonLoading(true);
         if (socket) {
            socket.emit("newReply", {
               payload: {
                  idDiscussion: discussionId,
                  textReply: content,
               },
               auth: authToken,
            });
         }
         setIsRichText(false)
         setComment("");
      } catch (error) {
         showNotification({
            color: "red",
            status: "error",
            title: "Ha ocurrido un problema al enviar tu comentario.",
            message: `Detalle: ${error}`
         });
      } finally {
         setButtonLoading(false);
      }
   };

   const toBack = (replyId) => {
      let requestData = {
         idDiscussion: discussionId,
      };

      if (replyId !== undefined) {
         requestData.idReplie = replyId;
      }

      return requestData;
   };

   const handleReportSubmit = async (replyId) => {
      try {
         await axios.put(
            `${process.env.REACT_APP_BACKEND_SERVER}campus/users/reportDiscussion`,
            toBack(replyId),
            {
               headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + authToken,
               },
            }
         );
         showNotification({
            color: "green",
            status: "success",
            title: "El comentario/tema fue denunciado exitosamente.",
            message: `Revisaremos la situación y tomaremos la decisión correspondiente.`,
         });
         setComment("");
      } catch (error) {
         showNotification({
            color: "red",
            status: "error",
            title: "Ha ocurrido un problema al enviar tu denuncia.",
            message: `Detalle: ${ERROR_HANDLER[error.request.status] || ERROR_HANDLER.defaultError}`,
         });
      }
   };


   const [runOnboarding, setRunOnboarding] = useState(false)
   const tour = useOnboarding({
      steps: TOUR_STEPS.foroDiscussion,
      page: "foroDiscussion",
      disabled: loading || !getDiscussion,
      runAgain: runOnboarding,
      lastBtnFunction: () => setRunOnboarding(false)
   })

   return (
      <>
         <OnBoardingBtn onClick={() => setRunOnboarding(true)} />

         <MainLayout>
            <div className="forumDiscussion-wrapper">
               {loading === true ? (
                  <ForumDiscussionSkeleton />
               ) : (
                  <div className={
                     isTablet
                        ? "forumDiscussion-father-div-mobile"
                        : "forumDiscussion-father-div"
                  }>
                     {isRichText
                        ? < ForumRichText handleSubmit={(content) => handleCommentSubmit(content)} setIsRichText={setIsRichText} />
                        : <>
                           <CourseClassHeaderNavigation btnText="Volver atrás" />
                           {tour}
                           <div>
                              <h2 className="forumDiscussion-header-title">Discusión:</h2>
                              <div className="forumDiscussion-themeCard-container">
                                 <ForumCard
                                    title={getDiscussion?.title || ""}
                                    username={
                                       `${getDiscussion?.user?.name} ${getDiscussion?.user?.lastname}` ||
                                       ""
                                    }
                                    text={getDiscussion?.discussionInit || "Parece que hubo un error al solicitar la discusión. Intenta nuevamente mas tarde."}
                                    profilePicture={getDiscussion?.user?.profilePicture || ""}
                                    tag={getDiscussion?.theme || ""}
                                    includeLink={false}
                                    hideCommentAmount={true}
                                    report={() => handleReportSubmit()}
                                    bgColor="var(--color-primary-lightest)"
                                 >
                                    <DecorativeEmptyCircle
                                       size={230}
                                       opacity={0.2}
                                       bottom={100}
                                       right={-100}
                                       color="#8BA7B2"
                                       borderWidth={48}
                                       zIndex={0}
                                    />
                                    <DecorativeEmptyCircle
                                       size={100}
                                       opacity={0.2}
                                       bottom={-30}
                                       right={50}
                                       color="#8BA7B2"
                                       borderWidth={16}
                                       zIndex={0}
                                    />
                                    <DecorativeEmptyCircle
                                       size={60}
                                       opacity={0.2}
                                       bottom={100}
                                       right={190}
                                       color="#8BA7B2"
                                       borderWidth={16}
                                       zIndex={0}
                                    />
                                 </ForumCard>
                              </div>
                           </div>
                           <h3 className="forumDiscussion-answers-title">Respuestas:</h3>
                           {getDiscussion?.replies?.map((item) => (
                              <div className="forumDiscussion-answers-div" key={(item?._id ?? item?.data?._id)}>
                                 <ForumCard
                                    bxShadow="var(--box-shadow-strong)"
                                    includeLink={false}
                                    hideCommentAmount={true}
                                    profilePicture={item.user?.profilePicture}
                                    username={`${item.user?.name} ${item.user?.lastname}`}
                                    text={(item?.replyText ?? item?.data?.replyText) || ""}
                                    report={() => handleReportSubmit((item?._id ?? item?.data?._id) || null)}
                                 />
                              </div>
                           ))}
                           <div className="forumDiscussion-bottomRef-empty" ref={answersEndRef}></div>
                           <div className="forumDiscussion-textInput-div">
                              <Textarea
                                 classNames={{ input: "forumDiscussion-textInput-input" }}
                                 onChange={handleCommentChange}
                                 value={comment}
                                 placeholder="Agregar una respuesta"
                              />
                              <Group align="center" p={8}>
                                 <Button
                                    disabled={isCommentEmpty}
                                    classNames={{ root: "forumDiscussion-button-root" }}
                                    onClick={() => handleCommentSubmit(comment)}
                                    fw="normal"
                                    bg="var(--color-tertiary-light)"
                                    loading={buttonLoading}
                                 >
                                    Enviar respuesta
                                 </Button>
                                 <Button id='ForumDiscussion-elaborated-response' variant='outline' color="var(--color-tertiary)" onClick={() => setIsRichText(true)}>Respuesta elaborada</Button>
                              </Group>
                           </div>
                        </>
                     }
                  </div>
               )}
            </div>
         </MainLayout>
      </>
   );
};
export default ForumDiscussion;
