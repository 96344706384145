import axios from "axios"
import { useContext, useEffect, useState } from "react"
import AuthContext from "../contexts/AuthContext"
import showNotification from "../utils/showNotification"
import { ERROR_HANDLER } from "../utils/error-handler"



export default function useOwnCourses(isMount) {
   const { authToken } = useContext(AuthContext)
   const [state, setState] = useState({ isLoading: true, error: null })
   const [ownCourses, setOwnCourses] = useState([])
   const [filters, setFilters] = useState({
      order: 'asc'
   })



   useEffect(() => {
      if (!isMount) return
      const getOwnCourses = async () => {

         try {
            const url = `${process.env.REACT_APP_BACKEND_SERVER}campus/users/courses`
            const { data } = await axios.get(url, {
               headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${authToken}`
               }
            })

            return setOwnCourses(data)

         } catch (error) {
            showNotification({
               color: "red",
               status: "error",
               title: "Ha ocurrido un problema al cargar todos tus cursos.",
               message: `Detalle: ${ERROR_HANDLER[error.request.status] || ERROR_HANDLER.defaultError}`,
               autoClose: 12000
           });
            setState(prevState => ({ ...prevState, error: 'Lo siento, hemos tenido un error al intentar recuperar todos los cursos a los que estas suscripto. Intenta nuevamente.' }))
         } finally {
            setState(prevState => ({ ...prevState, isLoading: false }))
         }
      }

      getOwnCourses()
   }, [authToken, isMount])


   /* funciones para filtrar por especialidad 👇 */

   // const getSpecialiesToFilter = (ownCourses) => {
   //    if(!ownCourses) return []
   //    const results = [...new Set(ownCourses.map(item => item.comercialInformation.specialty))]
   //    setSelectValues(results)
   // }

   // const filterCourses = (courses) => {
   //    console.log(courses)
   //    return courses.filter(course => (
   //       filters.specialty === 'Todas' ||
   //       filters.specialty === course.comercialInformation.specialty
   //    ))
   // }

   // const filteredCourses = filterCourses(ownCourses)

   const changeFilters = (value) => {
      setFilters(prevState => ({
         ...prevState,
         order: value
      }))
   }

   const sortCourses = (courses) => {
      if (!courses) return []
      const sorted = courses.slice().sort((a, b) => {
         if (filters.order === 'asc') {
            return a.percentage - b.percentage;
         } else {
            return b.percentage - a.percentage;
         }
      });
      return sorted;
   }

   const sortedCourses = sortCourses(ownCourses)

   return {
      state,
      ownCourses,
      // filteredCourses,
      changeFilters,
      sortedCourses,
      filters,
      // handleChangeFilter,
   }
}
