import './GlassCard.css'

const MAP_STAFF_TYPE = {
   teacher: 'Profesor',
   staff: 'Staff',
   director: 'Director'
}

export default function GlassCard({ item }) {

   return (
      <article class="GlassCard-main-container" >
         <div className='GlassCard-wrapper-container' >
            <img className="GlassCard--image" src={item?.profilePicture} alt={`${item?.name} ${item?.lastname}`} />

            <div className="GlassCard-info-wrapper">
               <h4 className="GlassCard-title">{item?.name} {item?.lastname}</h4>
               <span className="GlassCard-type">{MAP_STAFF_TYPE[item?.type]} </span>
            </div>

         </div>
      </article>
   )
}
