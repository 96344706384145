import { Button, Flex, Group, Image, Select, Stack, TextInput } from "@mantine/core";
import logo from "../assets/RegisterPage/logoForvet_dark.svg"
import {
   IconCalendar,
   IconCalendarWeek,
   IconCertificate,
   IconChevronDown,
   IconDotsVertical,
   IconDownload,
   IconExternalLink,
   IconFileTypeDoc,
   IconFileTypePdf,
   IconFileTypeZip,
   IconGavel,
   IconLayoutDashboard,
   IconMenu2,
   IconMessage,
   IconCheckbox,
   IconLayoutSidebarLeftCollapseFilled,
   IconSchool,
   IconArrowsSort,
   IconAlignBoxLeftTopFilled,
   IconMicroscope,
   IconListDetails,
   IconStarFilled,
   IconClockHour4,
   IconUserCheck,
   IconBooks,
   IconFileCertificate,
   IconCertificate2,
   IconChalkboard,
   IconBrandHipchat,
   IconMessage2,
   IconMessagePlus,
   IconSettings,
   IconBlockquote,
   IconLock,
   IconPencilPlus,
   IconUserCircle,
   IconHelp,
   IconSearch,
   IconMist,
   IconForms
} from "@tabler/icons-react";


export const TOUR_STEPS = {
   dashboard: {
      desktop: [
         {
            content: <Stack>
               <Image height={100} src={logo} alt='FORVET' />
               <h2 className="OnBoarding--text-title">Bienvenidos al campus de FORVET</h2>
               <p className='OnBoarding--text-body'>Esto es una guía para mostrarte todas las cosas que tenes disponibles en el campus.</p>
            </Stack>
            ,
            placement: "center",
            target: "body",
         },
         {
            content: <Stack align="center">
               <IconLayoutSidebarLeftCollapseFilled size={40} />
               <Stack align="center">
                  <h2 className="OnBoarding--text-title" style={{ fontWeight: 400 }}>Esta es la <strong>barra de navegación</strong></h2>
                  <p className='OnBoarding--text-body'>
                     Gracias a ella, podrás dirigirte a todas las vistas del campus.
                  </p>
               </Stack>
            </Stack>,
            spotlightPadding: 20,
            placement: "right",
            target: ".sidebar-container",
         },
         {
            content: (
               <Stack align="center">
                  <IconLayoutDashboard size={40} />

                  <Stack align="center">
                     <h2 className="OnBoarding--text-title" style={{ fontWeight: 400 }}>Vista general del <strong>Dashboard</strong></h2>
                     <p className='OnBoarding--text-body'>Podrás ver tus cursos mas recientes, novedades y recordatorios</p>
                  </Stack>

               </Stack>
            ),
            target: ".dashboard__main",
         },
         {
            content: (
               <Stack align="center">
                  <IconCalendarWeek size={40} />
                  <Stack align='center'>
                     <h2 className="OnBoarding--text-title" style={{ fontWeight: 400 }}><strong>Calendario</strong></h2>
                     <p className='OnBoarding--text-body'>En el verás los eventos a los que estas suscriptos. Tales como clases, exámenes, recuperatorios, etc.</p>
                  </Stack>
               </Stack>
            ),
            placement: "left",
            target: ".dashboard__calendar",
         },
      ],
      mobile: [
         {
            content: <Stack>
               <Image height={100} src={logo} alt='FORVET' />
               <h2 className="OnBoarding--text-title">Bienvenidos al campus de FORVET</h2>
               <p className='OnBoarding--text-body'>Esto es una guía para mostrarte todas las cosas que tenes disponibles en el campus.</p>
            </Stack>
            ,
            placement: "center",
            target: "body",
         },
         {
            content: (
               <Stack align="center">
                  <IconLayoutDashboard size={40} />

                  <Stack align="center">
                     <h2 className="OnBoarding--text-title" style={{ fontWeight: 400 }}>Vista general del <strong>Dashboard</strong></h2>
                     <p className='OnBoarding--text-body'>Podrás ver tus cursos mas recientes, novedades y recordatorios</p>
                  </Stack>

               </Stack>
            ),
            target: ".dashboard__main",
            placement: 'center'
         },
         {
            content: (
               <Stack>
                  <h2 className="OnBoarding--text-title" style={{ fontWeight: 400 }}>Menu de navegación</h2>
                  <Stack align="center" gap={4}>
                     <p className='OnBoarding--text-body'>A partir de este menu</p>
                     <Group w={'max-content'} style={{ borderRadius: '4px' }} bg={'#2c4b58'}>
                        <IconMenu2 size={40} color="#fff" />
                     </Group>
                     <p className='OnBoarding--text-body'>vas a poder navegar por las distintas páginas del campus</p>
                  </Stack>
                  <Stack align="center" gap={4}>
                     <p className='OnBoarding--text-body'>Con el botón</p>
                     <Group w={'max-content'} style={{ borderRadius: '4px' }} bg={'#2c4b58'}>
                        <IconCalendar size={40} color="#fff" />
                     </Group>
                     <p className='OnBoarding--text-body'>verás los eventos a los que estas suscriptos. Tales como clases, exámenes, recuperatorios, etc.</p>
                  </Stack>
               </Stack>
            ),
            target: ".sidebar-mobile-header-container",
            placement: 'bottom'
         },

      ]
   },
   ownCourses: [
      {
         content: (
            <Stack align="center">
               <IconSchool size={40} />
               <Stack align="center">
                  <h2 className="OnBoarding--text-title" style={{ fontWeight: 400 }}>Bienvenido a <strong>Mis Cursos</strong></h2>
                  <p className='OnBoarding--text-body'>Aquí encontras todas las capacitaciones a la que te encuentras inscripto.</p>
               </Stack>
            </Stack>
         ),
         placement: "center",
         target: ".OwnCourses-container",
      },
      {
         content: (
            <Stack align="center">
               <IconArrowsSort size={40} />
               <Stack align="center">
                  <h2 className="OnBoarding--text-title" style={{ fontWeight: 400 }}>Ordena tus cursos por porcentaje de avance</h2>
                  <p className='OnBoarding--text-body'>Con este botón podremos ver tus capacitaciones ordenadas según cuanto has completado de la misma.</p>
               </Stack>
            </Stack>
         ),
         placement: "bottom",
         target: "#OwnCoursesList--sort-button",
      },
      {
         content: (
            <Stack align="center">
               <IconAlignBoxLeftTopFilled size={40} />
               <Stack align="">
                  <h2 className="OnBoarding--text-title" style={{ fontWeight: 400 }}>Esta es la <strong>tarjeta</strong> de un curso</h2>
                  <p className='OnBoarding--text-body'>En ella encontrarás una imagen referencial, el título de la unidad academica y tu porcentaje de avance</p>
                  <p className='OnBoarding--text-body'>Haz click en la tarjeta para ver mas sobre ella y continuar.</p>
               </Stack>
            </Stack>
         ),
         placement: "bottom",
         target: ".OwnCourses-container .activeCardCourse-column-div",
      },
   ],
   course: [
      {
         content: (
            <Stack align="center">
               <IconMicroscope size={40} />
               <Stack align="center">
                  <h2 className="OnBoarding--text-title" style={{ fontWeight: 400 }}>Esta es la vista de un <strong>curso</strong></h2>
                  <p className='OnBoarding--text-body'>En ella, podrás ver clases, exámenes, materiales generales y específicos.</p>
               </Stack>
            </Stack>
         ),
         placement: "center",
         target: ".coursesMain-wrapper",
      },
      {
         content: (
            <Stack>
               <h2 className="OnBoarding--text-title" style={{ fontWeight: 400 }}>Esta es una tarjeta de una <strong>clase</strong></h2>
               <p className='OnBoarding--text-body'>En esta, se reperesenta la fecha de la misma, nombre y un link para poder acceder a la grabación.</p>
            </Stack>
         ),
         placement: "bottom",
         target: ".coursesCards-father-div",
      },
      // {
      //    content: (
      //       <Stack align="center">
      //          <IconPencil size={40}/>
      //          <Stack align="center">
      //          <h2 className="OnBoarding--text-title" style={{ fontWeight: 400 }}>Parece que tienes un <strong>examen</strong></h2>
      //          <p className='OnBoarding--text-body'>Podrás ver la fecha y hacer click para ver más de detalles</p>
      //          </Stack>
      //       </Stack>
      //    ),
      //    placement: "bottom",
      //    target: ".coursesCards-exam-father-div",
      // },
      {
         content: (
            <Stack>
               <h2 className="OnBoarding--text-title">Ver materiales</h2>
               <p className='OnBoarding--text-body'>Con este botón podras acceder a los materiales generales y específicos.</p>
            </Stack>
         ),
         placement: "bottom left",
         target: "#CoursesMain-toggle-button",
      },
   ],
   lesson: [
      {
         content: (
            <Stack align="center">
               <IconListDetails size={40} />
               <Stack align='center'>
                  <h2 className="OnBoarding--text-title" style={{ fontWeight: 400 }}>Has entrado a los detalles de una <strong>clase</strong></h2>
                  <p className='OnBoarding--text-body'>Esta es una vista muy importante, por lo que te recomendamos que veas todo lo que puedes hacer en ella.</p>
               </Stack>
            </Stack>
         ),
         placement: "center",
         target: ".coursesClass-wrapper",
      },
      {
         content: (
            <Stack align="center">
               <h2 className="OnBoarding--text-title" style={{ fontWeight: 400 }}>Aquí podrás ver las <strong>grabaciones</strong> de la clase</h2>
               <p className='OnBoarding--text-body'>Una vez que esté lista, podrás ingresar al video.</p>
            </Stack>
         ),
         placement: "bottom",
         target: ".courseClassHeader-closedClass-button-div",
      },
      {
         content: (
            <Stack align="center">
               <IconStarFilled size={40} />
               <Stack align="center">
                  <h2 className="OnBoarding--text-title">Valorar la clase</h2>
                  <p className='OnBoarding--text-body'>Dar un puntaje a la clase es importante para nosotros para saber que te gusta!</p>
               </Stack>
            </Stack>
         ),
         placement: "bottom",
         target: ".RateClassBanner-container",
      },
      {
         content: (
            <Stack align="center">
               <IconClockHour4 size={40} />
               <Stack align="center">
                  <h2 className="OnBoarding--text-title">Detalles de la clase</h2>
                  <p className='OnBoarding--text-body'>Es un resumen del dictado de la clase.</p>
                  <p className='OnBoarding--text-body'>Encontrarás fecha, hora y duración de la clase.</p>
               </Stack>
            </Stack>
         ),
         placement: "bottom",
         target: ".ClassDetails-card-container:first-child",
      },
      {
         content: (
            <Stack>
               <h2 className="OnBoarding--text-title">Material de la clase</h2>
               <p className='OnBoarding--text-body'>Vas a poder descargar el material específico subido para esa clase.</p>
            </Stack>
         ),
         placement: "top",
         target: ".ClassDetails-card-container:nth-child(2)",
      },
      {
         content: (
            <Stack align="center">
               <IconUserCheck size={40} />
               <Stack align="center">
                  <h2 className="OnBoarding--text-title">Acerca de los profesores</h2>
                  <p className='OnBoarding--text-body'>Te brindamos información acerca de los profesores que dicten la clase.</p>
               </Stack>
            </Stack>
         ),
         placement: "top",
         target: ".ClassDetails-card-container:last-child",
      },
      {
         content: (
            <Stack align="center">
               <IconPencilPlus size={40} />
               <Stack align="center">
                  <h2 className="OnBoarding--text-title" style={{ fontWeight: 400 }}>¡En nuestro campus vas a poder <strong>tomar notas!</strong></h2>
                  <p className='OnBoarding--text-body'>Siempre que desees, vas a poder escribir tus notas personales y guardarlas para cada clase.</p>
               </Stack>
            </Stack>
         ),
         placement: "top",
         target: ".mantine-RichTextEditor-root",
      },
      {
         content: (
            <Stack align="center">
               <IconDownload size={40} />
               <Stack align="center">
                  <h2 className="OnBoarding--text-title" style={{ fontWeight: 400 }}>Descarga tus <strong>notas.</strong></h2>
                  <p className='OnBoarding--text-body'>Una vez que hayas guardados tus notas, vas a poder descargar en formato <strong>PDF</strong> las notas tomadas para esta clase.</p>
               </Stack>
            </Stack>
         ),
         placement: "top",
         target: "#ClassNotes--downloadNotesButton",
      },
   ],
   materials: [
      {
         content: (
            <Stack align="center">
               <IconBooks size={40} />
               <Stack align="center">
                  <h2 className="OnBoarding--text-title" >Materiales de una clase</h2>
                  <p className='OnBoarding--text-body'>En esta vista verás los materiales disponbiles que tiene una clase.</p>
               </Stack>
            </Stack>
         ),
         placement: "center",
         target: ".materials__container",
      },
      {
         content: (
            <Stack>
               <h2 className="OnBoarding--text-title" >Lista de descarga</h2>
               <p className='OnBoarding--text-body'>En la tabla, aparecen los documentos que haya disponbile con distintas acciones:</p>
               <Stack>
                  <Flex gap='4px'>
                     <IconExternalLink />
                     <span>Abrir en otra ventana</span>
                  </Flex>
                  <Flex gap='4px'>
                     <IconDownload />
                     <span>Descargar el archivo</span>
                  </Flex>
                  <Stack align="start">
                     <span>Extensión del archivo:</span>
                     <Flex gap='4px'>
                        <IconFileTypePdf />
                        <IconFileTypeDoc />
                        <IconFileTypeZip />
                     </Flex>
                  </Stack>
               </Stack>

            </Stack>
         ),
         placement: "center",
         target: ".materialTable__container",
      },

   ],
   certificates: [
      {
         content: (
            <Stack align="center">
               <IconFileCertificate size={40} />
               <Stack align="center">
                  <h2 className="OnBoarding--text-title" style={{ fontWeight: 400 }}>Esta es la vista de los <strong>certificados</strong></h2>
                  <p className='OnBoarding--text-body'>En el campus, vas a poder ver reflejados todos tus logros.</p>
               </Stack>
            </Stack>
         ),
         placement: "center",
         target: ".certificates-layout",
      },
      {
         content: (
            <Stack>
               <h2 className="OnBoarding--text-title">Certificado</h2>
               <p className='OnBoarding--text-body'>Cada diploma obtenido se verá reflejado en una carta.</p>
            </Stack>
         ),
         placement: "right",
         target: ".certificatesCard-father-div",
      },
      {
         content: (
            <Stack align="center">
               <IconDownload size={40} />
               <Stack>
                  <h2 className="OnBoarding--text-title">Descargar certificado</h2>
                  <p className='OnBoarding--text-body'>Podrás descargar tu certificado en distintos formatos</p>
                  <Stack>
                     <Flex align='center' gap='5px'>
                        <IconCertificate color='#77BDE0' />
                        <p className='OnBoarding--text-body'>Certificado <strong>con</strong> nota <strong>con</strong> analítico</p>
                     </Flex>
                     <Flex align='center' gap='5px'>
                        <IconCertificate color='#77BDE0' />
                        <p className='OnBoarding--text-body'>Certificado <strong>con</strong> nota <strong>sin</strong> analítico</p>
                     </Flex>
                     <Flex align='center' gap='5px'>
                        <IconCertificate color='#77BDE0' />
                        <p className='OnBoarding--text-body'>Certificado <strong>sin</strong> nota <strong>con</strong> analítico</p>
                     </Flex>
                     <Flex align='center' gap='5px'>
                        <IconCertificate color='#77BDE0' />
                        <p className='OnBoarding--text-body'>Certificado <strong>sin</strong> nota <strong>sin</strong> analítico</p>
                     </Flex>
                  </Stack>
               </Stack>
            </Stack>
         ),
         placement: "top",
         target: "#certificatesCard-download-button",
      },
      {
         content: (
            <Stack align="center">
               <IconCertificate2 size={40} />
               <Stack align="center">
                  <h2 className="OnBoarding--text-title">Validez del certificado</h2>
                  <p className='OnBoarding--text-body'>Haciendo click en el boton</p>
                  <Button variant="outline" onClick={() => null}>
                     <IconChalkboard style={{ marginRight: '8px' }} />
                     Ver validez</Button>
                  <p className='OnBoarding--text-body'>serás redirigido hacia el diploma validado por la academia</p>
               </Stack>
            </Stack>
         ),
         placement: "top",
         target: "#certificatesCard-validation-button",
      },
   ],
   foro: {
      desktop: [
         {
            content: (
               <Stack align="center">
                  <IconBrandHipchat size={40} />
                  <Stack align="center">
                     <h2 className="OnBoarding--text-title" style={{ fontWeight: 400 }}>Bienvenido al <strong>Foro</strong></h2>
                     <p className='OnBoarding--text-body'>En esta sección vas a poder comunicarte con otros estudiantes sobre temas generales y tambien específicos a los que te encuentras suscripto.</p>
                  </Stack>
               </Stack>
            ),
            placement: "center",
            target: ".ForoPage-section-wrapper",
         },
         {
            content: (
               <Stack align="center">
                  <IconMessage2 size={40} />
                  <Stack>
                     <h2 className="OnBoarding--text-title">Esto es una discusión</h2>
                     <p className='OnBoarding--text-body'>Llamamos discusión a un tema abierto por un estudiante o staff del campus.</p>
                     <p className='OnBoarding--text-body'>Es una vista previa y general del tema.</p>
                     <p className='OnBoarding--text-body'>Haciendo click en cada discusión, podras ver más del tema y tambien responder a este.</p>
                  </Stack>
               </Stack>
            ),
            placement: "top",
            target: ".ForoCard-container",
         },
         {
            content: (
               <Stack>
                  <h2 className="OnBoarding--text-title">Botones de interacción</h2>
                  <Stack>
                     <p className='OnBoarding--text-body'>Para crear un nuevo tema debes hacer click en el boton</p>
                     <Button w='max-content' style={{ opacity: .7 }} onClick={() => null}>
                        <IconMessage style={{ marginRight: '8px' }} />
                        <span>Crear discusión</span>
                     </Button>
                  </Stack>
                  <Stack>
                     <p className='OnBoarding--text-body'>Para revisar las reglas del foro te indicamos que presiones el siguiente botón</p>
                     <Button w='max-content' variant="outline" color='#DF3576' onClick={() => null}>
                        <IconGavel style={{ marginRight: '8px' }} />
                        <span>Reglas del foro</span>
                     </Button>
                  </Stack>
                  <Stack>
                     <p className='OnBoarding--text-body'>Si lo deseas, puedes ordenar las discusiones por orden, de mas antiguos a más nuevos, o lo contrario.</p>
                     <Button w='max-content' style={{ opacity: .7 }} onClick={() => null}>
                        <IconChevronDown style={{ marginRight: '8px' }} />
                        <span>Mas recientes</span>
                     </Button>
                  </Stack>

               </Stack>
            ),
            placement: "bottom",
            target: ".ForoPageHeader--buttonGroup",
         },
      ],
      mobile: [
         {
            content: (
               <Stack align="center">
                  <IconBrandHipchat size={40} />
                  <Stack align="center">
                     <h2 className="OnBoarding--text-title" style={{ fontWeight: 400 }}>Bienvenido al <strong>Foro</strong></h2>
                     <p className='OnBoarding--text-body'>En esta sección vas a poder comunicarte con otros estudiantes sobre temas generales y tambien específicos a los que te encuentras suscripto.</p>
                  </Stack>
               </Stack>
            ),
            placement: "center",
            target: ".ForoPage-section-wrapper",
         },
         {
            content: (
               <Stack align="center">
                  <IconMessage2 size={40} />
                  <Stack>
                     <h2 className="OnBoarding--text-title">Esto es una discusión</h2>
                     <p className='OnBoarding--text-body'>Llamamos discusión a un tema abierto por un estudiante o staff del campus.</p>
                     <p className='OnBoarding--text-body'>Es una vista previa y general del tema.</p>
                     <p className='OnBoarding--text-body'>Haciendo click en cada discusión, podras ver más del tema y tambien responder a este.</p>
                  </Stack>
               </Stack>
            ),
            placement: "top",
            target: ".ForoCard-container",
         },
         {
            content: (
               <Stack>
                  <h2 className="OnBoarding--text-title">Botones de interacción</h2>
                  <Stack>
                     <Stack align="center">
                        <p className='OnBoarding--text-body'>Para crear un nuevo tema debes hacer click en el boton vertical</p>
                        <IconDotsVertical />
                     </Stack>
                     <p style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center'
                     }}
                     >
                        Luego deberás presionar el botón
                        <Flex
                           style={{ borderRadius: '8px' }}
                           py={6}
                           px={8}
                           maw={'max-content'}
                           justify={'center'}
                           bg={'#bbbbbb'}
                           align={'center'}
                        >
                           <IconMessage style={{ marginRight: '8px' }} />
                           <span>Nueva discusión</span>
                        </Flex>
                     </p>
                     <Stack align="center">
                        <p className='OnBoarding--text-body'>Para revisar las reglas del foro te indicamos que presiones el siguiente botón</p>
                        <Flex
                           style={{ borderRadius: '8px' }}
                           py={6}
                           px={8}
                           maw={'max-content'}
                           justify={'center'}
                           bg={'#bbbbbb'}
                           align={'center'}
                        >
                           <IconGavel style={{ marginRight: '8px' }} />
                           <span>Reglas del foro</span>
                        </Flex>
                     </Stack>
                     <Stack>
                        <p className='OnBoarding--text-body'>Si lo deseas, puedes ordenar las discusiones por orden, de mas antiguos a más nuevos, o lo contrario.</p>
                        <Button m='auto' w='max-content' style={{ opacity: .7 }} onClick={() => null}>
                           <IconChevronDown style={{ marginRight: '8px' }} />
                           <span>Mas recientes</span>
                        </Button>
                     </Stack>
                  </Stack>
               </Stack>
            ),
            placement: "bottom",
            target: ".ForoPageHeader--mobile-container",
         },
      ],
   },
   foroDiscussion: [
      {
         content: (
            <Stack>
               <h2 className="OnBoarding--text-title">Has entrado a una discusión</h2>
               <p className='OnBoarding--text-body'>Este es un gran paso para poder interactuar con otras personas.</p>
            </Stack>
         ),
         placement: "center",
         target: ".forumDiscussion-wrapper",
      },
      {
         content: (
            <Stack align="center">
               <IconMessagePlus size={40} />
               <Stack align="center">
                  <h2 className="OnBoarding--text-title">Responder a una discusión</h2>
                  <p className='OnBoarding--text-body'>Podras opinar sobre este tema con una respuesta simple o elaborada.</p>
               </Stack>
            </Stack>
         ),
         placement: "top",
         target: ".forumDiscussion-textInput-div",
      },
      {
         content: (
            <Stack>
               <h2 className="OnBoarding--text-title">Respuesta elaborada</h2>
               <p className='OnBoarding--text-body'>Al hacer click en el boton <Button onClick={() => null} variant='outline' color="#DF3576" w='max-content' >Respuesta elaborada</Button> se abrirá un editor en el cual podras subir imagenes y darle formato a tu texto </p>
            </Stack>
         ),
         placement: "top",
         target: "#ForumDiscussion-elaborated-response",
      },
   ],
   opportunities: [
      {
         content: (
            <Stack>
               <h2 className="OnBoarding--text-title">Oportunidades</h2>
               <p className='OnBoarding--text-body'>Aquí encontrarás los beneficios de pertenecer a la comunidad.</p>
               <p className='OnBoarding--text-body'>Te recomendamos que revises semanalmente las <strong>oportunidades</strong> que tenes para no desaprovechar ninguna.</p>
            </Stack>
         ),
         placement: "center",
         target: ".OpportunitiesPage-container",
      },
   ],
   profile: [
      {
         content: (
            <Stack align="center">
               <IconSettings size={40} />
               <Stack align="center">
                  <h2 className="OnBoarding--text-title">¡Has ingresado a tu perfil!</h2>
                  <p className='OnBoarding--text-body'>En esta vista, vas a poder cambiar tu información personal.</p>
                  <p className='OnBoarding--text-body'>Te recomendamos que completes tu perfil con todos los datos. Vas a tener una mejor experiencia dentro del campus.</p>
               </Stack>
            </Stack>
         ),
         placement: "center",
         target: ".UserProfilePage-container",
      },
      {
         content: (
            <Stack align="center">
               <IconUserCircle size={40} />
               <Stack align="center">
                  <h2 className="OnBoarding--text-title">Cambiar foto de perfil</h2>
                  <p className='OnBoarding--text-body'>Vas a poder cambiar tu <strong>avatar</strong> haciendo click en el botón</p>
                  <span style={{ backgroundColor: '#b8b8b8', padding: '2px 8px', borderRadius: '8px' }}>Seleccionar archivo</span>
               </Stack>
            </Stack>
         ),
         placement: "bottom",
         target: ".RegisterPage-avatar-group",
      },
      {
         content: (
            <Stack>
               <h2 className="OnBoarding--text-title">Vas a poder cambiar todos estos campos</h2>
               <Stack>
                  <Flex>
                     <IconCheckbox />
                     <span>Nombre</span>
                  </Flex>
                  <Flex>
                     <IconCheckbox />
                     <span>Apellido</span>
                  </Flex>
                  <Flex>
                     <IconCheckbox />
                     <span>Teléfono</span>
                  </Flex>
                  <Flex>
                     <IconCheckbox />
                     <span>Teléfono</span>
                  </Flex>
                  <Flex>
                     <IconCheckbox />
                     <span>Nacionalidad</span>
                  </Flex>
                  <Flex>
                     <IconCheckbox />
                     <span>Ciudad</span>
                  </Flex>
                  <Flex>
                     <IconCheckbox />
                     <span>Género</span>
                  </Flex>
                  <Flex>
                     <IconCheckbox />
                     <span>Fecha de nacimiento</span>
                  </Flex>
               </Stack>

            </Stack>
         ),
         placement: "bottom",
         target: '.mantine-Grid-inner',
      },
      {
         content: (
            <Stack align="center">
               <IconBlockquote size={40} />
               <Stack align="center">
                  <h2 className="OnBoarding--text-title">Descripción personal</h2>
                  <p className='OnBoarding--text-body'>Este será un espacio donde escribas sobre vos.</p>
                  <p className='OnBoarding--text-body'>Preséntate, cuanta quien sos.</p>
                  <p className='OnBoarding--text-body'>Vas a poder contar tus logros, tus metas y deseos, lo que quieres lograr.</p>
                  <p className='OnBoarding--text-body'>Eres libre de escribir lo que deseas.</p>
               </Stack>
            </Stack>
         ),
         placement: "top",
         target: '#UserProfileForm-description',
      },
      {
         content: (
            <Stack>
               <h2 className="OnBoarding--text-title">Mascota preferida</h2>
               <p className='OnBoarding--text-body'>Esto es opcional, pero al elegir una mascota tendremos una pequeña sorpresa para vos.</p>
            </Stack>
         ),
         placement: "top",
         target: '.RegisterPage-pet-container',
      },
      {
         content: (
            <Stack align="center">
               <IconLock size={40} />
               <h2 className="OnBoarding--text-title">Cambiar contraseña</h2>
               <Stack>
                  <p className='OnBoarding--text-body'>Para cambiar la contraseña, primero deberás presionar el botón </p>
                  <span style={{
                     color: "#a3a3a3",
                     textDecoration: "underline"
                  }}>Cambiar contraseña</span>
                  <Stack bg='#77bee128' py={12} px={4} style={{ borderRadius: "8px" }}>
                     <p className='OnBoarding--text-body'>Consideraciones de contraseña:</p>
                     <Flex align='center' gap={'10px'}>
                        <IconCheckbox />
                        Longitud de 8 caracteres como mínimo
                     </Flex>
                     <Flex align='center' gap={'10px'}>
                        <IconCheckbox />
                        Contener al menos 1 minúscula
                     </Flex>
                     <Flex align='center' gap={'10px'}>
                        <IconCheckbox />
                        Contener al menos 1 mayúscula
                     </Flex>
                     <Flex align='center' gap={'10px'}>
                        <IconCheckbox />
                        Contener al menos 1 número
                     </Flex>
                     <Flex align='center' gap={'10px'}>
                        <IconCheckbox />
                        Contener al menos 1 símbolo
                     </Flex>

                  </Stack>
               </Stack>
            </Stack>
         ),
         placement: "top",
         target: '.UserProfileForm-changePass',
      },
      {
         content: (
            <Stack>
               <h2 className="OnBoarding--text-title">Guardar cambios</h2>
               <Stack>
                  <p className='OnBoarding--text-body'>Para guardar todos los cambios que hayas hecho, tenés que presionar el botón</p>
                  <Button
                     m='max-content'
                     color="#DF3576"
                     onClick={() => null}
                  >
                     Guardar cambios
                  </Button>
               </Stack>
               <Stack>
                  <p className='OnBoarding--text-body'>Para salir sin guardar nada, presiona el botón</p>
                  <Button
                     m='max-content'
                     variant="outline"
                     color="#DF3576"
                     onClick={() => null}
                  >
                     Cancelar
                  </Button>
                  <p className='OnBoarding--text-body'>O simplemente navegar hacia otra vista. Los cambios <strong>no</strong> se guardarán</p>
               </Stack>
            </Stack>
         ),
         placement: "bottom",
         target: '.UserProfileForm-rowInputGroup',
      },
   ],
   help: [
      {
         content: (
            <Stack align="center">
               <IconHelp size={40} />
               <Stack align="center">
                  <h2 className="OnBoarding--text-title">Centro de Ayuda</h2>
                  <p className='OnBoarding--text-body'>Siempre que estes un poco perdido o necesites un poco de ayuda.</p>
               </Stack>
            </Stack>
         ),
         placement: "center",
         target: ".helpBody-container",
      },
      {
         content: (
            <Stack align="center">
               <IconSearch size={40} />
               <Stack align="center">
                  <h2 className="OnBoarding--text-title">Buscador</h2>
                  <p className='OnBoarding--text-body'>Para ahorrarte tiempo, hemos diseñado una forma rápida para obtener las respuestas que mejor coincidan con tu búsqueda al instante.</p>
               </Stack>
            </Stack>
         ),
         placement: "bottom",
         target: ".helpHeader-father-div",
      },
      {
         content: (
            <Stack align="center">
               <IconMist size={40} />
               <Stack align="center">
                  <h2 className="OnBoarding--text-title">Respuestas y soluciones</h2>
                  <p className='OnBoarding--text-body'>En este espacio es donde verás las respuestas.</p>
               </Stack>
            </Stack>
         ),
         placement: "top",
         target: ".helpBody-accordion-div",
      },
      {
         content: (
            <Stack align="center">
               <IconForms size={40} />
               <Stack align="center">
               <h2 className="OnBoarding--text-title">Formulario de consultas avanzadas</h2>
               <p className='OnBoarding--text-body'>Si no encontraste lo que buscabas, tendrás la posibilidad de <strong>enviar tu consulta</strong> a soporte y ayuda.</p>
               <div className="advancedQueryForm-formDiv">
                  <Select
                     label="Tipo de problema"
                     placeholder="Seleccionar tipo"
                  />
                  <TextInput
                     label="Consulta"
                     placeholder="Escriba su consulta aquí..."
                  />
                  <Button
                     fw="normal"
                     classNames={{ root: "advancedQueryForm-button-root" }}
                     onClick={() => null}
                  >
                     Enviar consulta
                  </Button>
               </div>
               </Stack>
            </Stack>
         ),
         placement: "center",
         target: ".helpBody-container",
      },
   ]
}
