import { Flex, Skeleton, Stack } from '@mantine/core'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import React from 'react'

export default function LoginStaffSkeleton() {
   return (
      <Flex
         gap={40}
         align='center'
         wrap='nowrap'
         style={{
            overflowX: 'hidden'
         }}
      >
         {/* card */}
         <Stack align='center' w={250} gap={8} p={10}>
            {/* img */}
            <Skeleton h={100} w={100} circle animate />

            <Stack gap={4} align='center' p={0}>
               {/* name */}
               <Skeleton h={18} w={95} circle animate />
               {/* type */}
               <Skeleton h={20} w={65} circle animate />
            </Stack>
         </Stack>

         {/* card */}
         <Stack align='center' w={250} gap={8} p={10}>
            {/* img */}
            <Skeleton h={100} w={100} circle animate />

            <Stack gap={4} align='center' p={0}>
               {/* name */}
               <Skeleton h={18} w={95} circle animate />
               {/* type */}
               <Skeleton h={20} w={65} circle animate />
            </Stack>
         </Stack>

         {/* card */}
         <Stack align='center' w={250} gap={8} p={10}>
            {/* img */}
            <Skeleton h={100} w={100} circle animate />

            <Stack gap={4} align='center' p={0}>
               {/* name */}
               <Skeleton h={18} w={95} circle animate />
               {/* type */}
               <Skeleton h={20} w={65} circle animate />
            </Stack>
         </Stack>

         {/* card */}
         <Stack align='center' w={250} gap={8} p={10}>
            {/* img */}
            <Skeleton h={100} w={100} circle animate />

            <Stack gap={4} align='center' p={0}>
               {/* name */}
               <Skeleton h={18} w={95} circle animate />
               {/* type */}
               <Skeleton h={20} w={65} circle animate />
            </Stack>
         </Stack>

         {/* card */}
         <Stack align='center' w={250} gap={8} p={10}>
            {/* img */}
            <Skeleton h={100} w={100} circle animate />

            <Stack gap={4} align='center' p={0}>
               {/* name */}
               <Skeleton h={18} w={95} circle animate />
               {/* type */}
               <Skeleton h={20} w={65} circle animate />
            </Stack>
         </Stack>
      </Flex>
   )
}
