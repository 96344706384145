import CourseClassHeaderNavigation from '../../components/CourseClassHeaderNavigation/CourseClassHeaderNavigation'
import OwnCoursesList from '../../features/OwnCourses/OwnCoursesList/OwnCoursesList'
import MainLayout from '../../layouts/MainLayout/MainLayout'
import './OwnCourses.css'

export default function OwnCourses() {
  

   return (
      <MainLayout>
         <section className='OwnCourses-container'>

            <CourseClassHeaderNavigation noButton title='Mis cursos' />

            <OwnCoursesList />

         </section>
      </MainLayout>
   )
}
