import React, { useContext, useEffect, useState } from "react";
import MainLayout from "../../layouts/MainLayout/MainLayout";
import "./Dashboard.css";
import ActiveCourseCard from "../../features/Dashboard/CourseCard/ActiveCourseCard";
import DailyRecommendationCard from "../../features/Dashboard/DailyRecommendationCard/DailyRecommendationCard";
import CalendarAside from "../../components/CalendarAside/CalendarAside";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import AppContext from "../../contexts/AppContext";
import ReminderAndSponsors from "../../features/Dashboard/ReminderAndSponsors/ReminderAndSponsors";
import SurveysFormat from "../../components/SurveysFormat/SurveysFormat";
import Surveys from "../../features/Surveys/Surveys";
import useOnboarding from "../../hooks/useOnboarding";
import { TOUR_STEPS } from "../../utils/tour";
import OnBoardingBtn from "../../components/OnBoardingBtn/OnBoardingBtn";


function Dashboard() {
   const { currentUser, isCalendarCollapsed, remainingSurvey } = useContext(AppContext);
   const { width } = useWindowDimensions();
   const isTablet = width < 1000;

   const tourSteps = isTablet ? TOUR_STEPS.dashboard.mobile : TOUR_STEPS.dashboard.desktop

   const [runOnboarding, setRunOnboarding] = useState(false)
   const tour = useOnboarding({
      steps: tourSteps,
      page: 'dashboard',
      runAgain: runOnboarding,
      lastBtnFunction: () => setRunOnboarding(false)
   });


   return (
      <>
         <OnBoardingBtn onClick={() => setRunOnboarding(true)} />
         <MainLayout>
            {tour}

            <div
               className={`${isCalendarCollapsed
                  ? "dashboard__layout--collapsed"
                  : "dashboard__layout"
                  }`}
            >
                <div className="dashboard__header">
                    <p className="dashboard__header__userName">¡Hola, {currentUser.name}!</p>
                </div>
                {(!isTablet) && (
                    <div className="dashboard__calendar">
                        <CalendarAside/>
                    </div>
                )}
                <div className="dashboard__main">
                    <ActiveCourseCard/>
                    <ReminderAndSponsors/>
                    <Surveys remainingSurvey={remainingSurvey}/>
                    <DailyRecommendationCard/>
                </div>
            </div>
         </MainLayout>
      </>
   );
}

export default Dashboard;
