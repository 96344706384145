import './LoginHeader.css'
import logoForvet from '../../../assets/RegisterPage/logoForvet_dark.svg'
import { Button, Flex } from '@mantine/core'
import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';
import { IconChevronDown } from '@tabler/icons-react';
import LoginMegaMenu from '../LoginMegaMenu/LoginMegaMenu';
import useWindowDimensions from '../../../hooks/useWindowDimensions';

export default function LoginHeader({ acaUnits, officialUSD, isUSD }) {

   const { loginWithRedirect } = useAuth0();

   const [type, setType] = useState('')
   const [isMenu, setIsMenu] = useState(false)

   const onMouseEnter = (_type) => {
      setType(_type)
      setIsMenu(true)
   }

   const onMouseLeave = () => {
      setType('')
      setIsMenu(false)
   }

   const { width } = useWindowDimensions()
   const isMobile = width <= 768

   return (
      <header className='LoginPage-header-container'>

         <figure className='LoginPage-header--group'>
            < img
               className='LoginPage-header--logo'
               src={logoForvet}
               alt='logo FORVET'
            />
         </figure>

         {!isMobile && <Flex w='100%' justify='center' gap={0}>
            <Button
               h={50}
               onMouseEnter={() => onMouseEnter('diplomado')}
               onMouseLeave={() => onMouseLeave()}
               variant='transparent'
               color='var(--color-text-secondary)'
               className={`${type === 'diplomado' ? 'LoginMegaMenu-btn--active' : ''}`}
            >
               <span>Diplomados</span>
               <IconChevronDown className='LoginMegaMenu--chevronIcon' />
            </Button>
            <Button
               h={50}
               onMouseEnter={() => onMouseEnter('curso')}
               onMouseLeave={() => onMouseLeave()}
               variant='transparent'
               color='var(--color-text-secondary)'
               className={`${type === 'curso' ? 'LoginMegaMenu-btn--active' : ''}`}
            >
               <span>Cursos</span>
               <IconChevronDown className='LoginMegaMenu--chevronIcon' />
            </Button>
            <Button
               h={50}
               onMouseEnter={() => onMouseEnter('posgrado')}
               onMouseLeave={() => onMouseLeave()}
               variant='transparent'
               color='var(--color-text-secondary)'
               className={`${type === 'posgrado' ? 'LoginMegaMenu-btn--active' : ''}`}
            >
               <span>Posgrados</span>
               <IconChevronDown className='LoginMegaMenu--chevronIcon' />
            </Button>
         </Flex>}

         <div className='LoginPage-header--group'>
            <Button size="lg" radius='md' color='var(--color-tertiary)' onClick={() => loginWithRedirect()}>Ingresar</Button>
         </div>

         {!isMobile && <LoginMegaMenu
            isMenu={isMenu}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            type={type}
            acaUnits={acaUnits}
            officialUSD={officialUSD}
            isUSD={isUSD}
         />
         }
      </header>
   )
}
