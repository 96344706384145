import { Button } from "@mantine/core";
import "./CertificatesCard.css";
import moment from "moment";
import defaultImage from "../../../assets/Certificate/DefaultCourseCertified.png";
import { IconChalkboard, IconDownload } from "@tabler/icons-react";
import {useContext} from "react";
import AppContext from "../../../contexts/AppContext";

const CertificatesCard = ({ certificates, handleDownloadClick, setIsModal }) => {
   const {currentUser} = useContext(AppContext);

   return (
      <div className="certificatesCard-layout">
         {certificates?.map((certificate) => (
            <div key={certificate._id} className="certificatesCard-father-div">
               <div>
                  <img
                     className="certificatesCard-image"
                     src={defaultImage}
                     height={160}
                     alt="Certificates"
                  />
               </div>

               <div className="certificatesCard-infoDiv">
                  <p className="certificatesCard-courseTitle">
                     {certificate.academicUnitName}
                  </p>
                  <p className="certificatesCard-dateText">
                     {moment(certificate.dateOfIssue).format("DD/MM/YYYY")}
                  </p>

                  <Button
                     id="certificatesCard-download-button"
                     color="var(--color-tertiary)"
                     fw="normal"
                     fullWidth
                     mt="md"
                     radius="md"
                     onClick={() => {
                        setIsModal(true);
                        const {academicUnitId, download, note} = certificate;
                        handleDownloadClick(academicUnitId, download, note)}}
                  >
                     <IconDownload />
                     <p className="certificatesCard-buttons-textSize">Descargar</p>
                  </Button>
                  <Button
                     id="certificatesCard-validation-button"
                     classNames={{ root: "certificatesCard-secondButton-root" }}
                     fw="normal"
                     color="var(--color-secondary)"
                     variant="outline"
                     fullWidth
                     mt="md"
                     component="a"
                     target="_blank"
                     href={`/certificaciones/validacion/${currentUser._id}/${certificate.academicUnitId}`}
                  >
                     <IconChalkboard />
                     <p className="certificatesCard-buttons-textSize">Ver validez</p>
                  </Button>
               </div>
            </div>

         ))}
      </div>
   );
};
export default CertificatesCard;
