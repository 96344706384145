import { Button, Flex, Stack } from "@mantine/core";
import { IconArrowRight, IconArrowLeft } from "@tabler/icons-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import Joyride, { STATUS } from "react-joyride";


const joyrideStyles = {
   options: {
      zIndex: 10000,

   },
   tooltip: {
      padding: "20px",
      backgroundColor: "#fff",
      borderRadius: "8px",
      boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
      maxWidth: "90%", // Make it responsive by using percentage
      width: "auto", // Allow it to grow based on content
      '@media (max-width: 768px)': { // Media query for mobile
         width: '95%', // Use almost the entire screen width
         height: '90%', // Use almost the entire screen height
         borderRadius: '4px', // Slightly reduce the border-radius for smaller screens
      },
   },
   buttonNext: {
      display: 'none',
   },
   buttonSkip: {
      display: 'none',
   },
   buttonBack: {
      display: 'none',
   },
};

export const LOCAL_STORAGE_TOUR_KEY = "Forvet-Onboarding"

export const setItemOnLocalStorage = (page) => {

   const tourViewed = window.localStorage.getItem(LOCAL_STORAGE_TOUR_KEY);
   if (!tourViewed) {
      window.localStorage.setItem(LOCAL_STORAGE_TOUR_KEY, JSON.stringify([page]));
   } else {
      let parsedTourViewed = JSON.parse(tourViewed);

      // Verificamos si parsedTourViewed es un array, de lo contrario lo reestablecemos
      if (!Array.isArray(parsedTourViewed)) {
         parsedTourViewed = [];
      }

      // Evitar agregar duplicados
      if (!parsedTourViewed.includes(page)) {
         parsedTourViewed.push(page);
      }

      window.localStorage.setItem(LOCAL_STORAGE_TOUR_KEY, JSON.stringify(parsedTourViewed));
   }
}


export default function useOnboarding({ steps, disabled, page, lastBtnFunction, runAgain }) {

   const [run, setRun] = useState(false);

   useEffect(() => {
      if (disabled) return;

      if (runAgain) return setRun(true)

      const initializeTourViewed = () => {
         const storedData = window.localStorage.getItem(LOCAL_STORAGE_TOUR_KEY);

         if (!storedData) {
            window.localStorage.setItem(LOCAL_STORAGE_TOUR_KEY, JSON.stringify([page]));
            return true;
         }

         try {
            const parsedData = JSON.parse(storedData);

            if (Array.isArray(parsedData)) {
               if (!parsedData.includes(page)) {
                  setRun(true);
               }
            } else {
               console.warn("Unexpected data structure in localStorage, resetting to an array.");
               window.localStorage.setItem(LOCAL_STORAGE_TOUR_KEY, JSON.stringify([page]));
               return true;
            }
         } catch (error) {
            console.error("Error parsing localStorage data", error);
         }

         return false;
      };

      const shouldRun = initializeTourViewed();
      if (shouldRun) {
         setRun(true);
      }
   }, [disabled, page, runAgain]);

   const handleJoyrideCallback = useCallback((data) => {
      const { status } = data;
      const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

      if (finishedStatuses.includes(status)) {
         setRun(false);
      }

   }, []);


   const tour = useMemo(

      () => {

         const Tooltip = ({
            continuous,
            index,
            step,
            backProps,
            closeProps,
            primaryProps,
            tooltipProps,
            isLastStep,
            skipProps
         }) => (
            <Stack miw={{ base: '80vw', md: 'auto' }} {...tooltipProps} style={{ padding: "20px", backgroundColor: "#fff", borderRadius: "8px" }}>
               <Stack align="center">
                  {step.title && <h4>{step.title}</h4>}
                  {step.content}
               </Stack>
               <Stack align="flex-start">
                  <Flex w='100%' align='center' justify={index > 0 ? 'space-between' : 'flex-end'}>
                     {index > 0 && (
                        <Button variant="outline" {...backProps}>
                           <IconArrowLeft />
                           Volver
                        </Button>
                     )}
                     {continuous && !isLastStep && (
                        <Button {...primaryProps} >
                           ¡Entendido!
                           <IconArrowRight style={{ marginLeft: 4 }} />
                        </Button>
                     )}
                     {isLastStep && (
                        <Button {...primaryProps} onClick={(event) => {
                           setItemOnLocalStorage(page);
                           lastBtnFunction && lastBtnFunction();
                           closeProps.onClick(event);

                           // Otras acciones que quieras realizar al finalizar el tour
                        }}>
                           ¡Completar guía!
                        </Button>
                     )}
                  </Flex>
                  <Button variant="transparent" {...skipProps}
                     onClick={(event) => {
                        setItemOnLocalStorage(page);
                        lastBtnFunction && lastBtnFunction();
                        skipProps.onClick(event);
                     }}
                  >
                     Saltarse la guía
                  </Button>
               </Stack>
            </Stack>
         );
         return (
            <Joyride
               // callback={handleJoyrideCallback}
               // continuous={true}
               // run={run}
               // scrollToFirstStep={true}
               // showProgress={false}
               // showSkipButton={true}
               // hideBackButton
               // hideCloseButton
               // disableCloseOnEsc
               // steps={steps}
               // styles={joyrideStyles}
               // spotlightClicks
               // locale={{
               //    last: <Button onClick={() => {
               //       setItemOnLocalStorage(page);
               //       lastBtnFunction && lastBtnFunction();
               //    }}>¡Completar guía!</Button>,
               //    next: (
               //       <Button >¡Entendido!</Button>
               //    ),
               //    skip: (
               //       <Button variant="transparent" onClick={() => {
               //          setItemOnLocalStorage(page);
               //          lastBtnFunction && lastBtnFunction()}}>Saltarse la guía</Button>
               //    )
               // }}
               callback={handleJoyrideCallback}
               continuous={true}
               run={run}
               scrollToFirstStep={true}
               showProgress={false}
               showSkipButton={true}
               hideBackButton
               hideCloseButton
               disableCloseOnEsc
               steps={steps}
               styles={joyrideStyles}
               spotlightClicks
               tooltipComponent={Tooltip}
            />
         )

      },
      [
         steps,
         handleJoyrideCallback,
         run,
         lastBtnFunction, page
      ]
   );

   return tour;
}
