import './Opportunities.css'
import CourseClassHeaderNavigation from '../../components/CourseClassHeaderNavigation/CourseClassHeaderNavigation'
import OpportunitiesCardContainer
   from '../../features/Opportunities/OpportunitiesCardContainer/OpportunitiesCardContainer'
import OpportunitiesCarousel from '../../features/Opportunities/OpportunitiesCarousel/OpportunitiesCarousel'
import MainLayout from '../../layouts/MainLayout/MainLayout'
import OpportunityModal from '../../features/Opportunities/OpportunityModal/OpportunityModal'
import useOpportunities from '../../hooks/useOpportunities'
import OpportunitiesSkeletonCard from '../../features/Opportunities/OpportunitiesSkeletonCard/OpportunitiesSkeletonCard'
import { IconMoodWrrr, IconReportSearch } from '@tabler/icons-react'
import useOnboarding from '../../hooks/useOnboarding'
import { TOUR_STEPS } from '../../utils/tour'
import { useState } from 'react'
import OnBoardingBtn from '../../components/OnBoardingBtn/OnBoardingBtn'


export default function Opportunities() {

   const {
      bannerHighlight,
      restOfItems,
      isModal,
      selectedOportunity,
      handleClickCard,
      setIsModal,
      state
   } = useOpportunities()

   const [runOnboarding, setRunOnboarding] = useState(false)
   const tour = useOnboarding({
      page: 'opportunities',
      steps: TOUR_STEPS.opportunities,
      disabled: state.isLoading || state.error,
      runAgain: runOnboarding,
      lastBtnFunction: () => setRunOnboarding(false)
   })

   return (
      <>
         <OnBoardingBtn onClick={() => setRunOnboarding(true)} />

         <MainLayout>
            <div className='OpportunitiesPage-wrapper'>
               <section className='OpportunitiesPage-container'>

                  {state.error &&
                     <>
                        <div className='OpportunitiesPage-error-container'>
                           < IconMoodWrrr color='var(--color-red)' size={150} />
                           <p className='OpportunitiesPage-error--text'>{state.error}</p>
                        </div>
                     </>
                  }

                  {!state.error &&
                     <div className='OpportunitiesPage-header'>
                        < CourseClassHeaderNavigation noButton title='Oportunidades de FORVET' />
                     </div>
                  }

                  {state.isLoading && < OpportunitiesSkeletonCard />}

                  {!state.isLoading && !state.error &&
                     <>
                        {tour}
                        {bannerHighlight.length !== 0 &&
                           < OpportunitiesCarousel itemsToMap={bannerHighlight} handleClickCard={handleClickCard} />}

                        {restOfItems.length !== 0 && < OpportunitiesCardContainer handleClickCard={handleClickCard} itemsToMap={restOfItems} />}

                        {bannerHighlight.length === 0 && restOfItems.length === 0 &&
                           <div className='OpportunitiesPage-notResults-container'>
                              <p className='OpportunitiesPage-notResults--text'>¡Ups!</p>
                              <p className='OpportunitiesPage-notResults--text'>Parece que no hay oportunidades
                                 disponibles al momento.</p>
                              < IconReportSearch size={125} className='OpportunitiesPage-notResults--icon' />
                           </div>
                        }

                        {isModal && < OpportunityModal isModal={isModal} setIsModal={setIsModal}
                           selectedOportunity={selectedOportunity} />}
                     </>
                  }

               </section>
            </div>
         </MainLayout>
      </>
   )
}
