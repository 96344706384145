import React from 'react'
import DecorativeEmptyCircle from '../../../components/DecorativeEmptyCircle/DecorativeEmptyCircle'

export default function LoginDecorativeCircles() {
   return (
      <>
         <DecorativeEmptyCircle
            size={180}
            opacity={0.4}
            top={200}
            left={44}
            color="#A6B3BB"
            borderWidth={28}
         />


         <DecorativeEmptyCircle
            size={230}
            opacity={0.2}
            top={300}
            right={-100}
            color="#8BA7B2"
            borderWidth={48}
         />

         <DecorativeEmptyCircle
            size={230}
            opacity={0.2}
            top={100}
            left={500}
            color="#8BA7B2"
            borderWidth={48}
         />

         <DecorativeEmptyCircle
            size={100}
            opacity={0.2}
            top={305}
            left={255}
            color="#8BA7B2"
            borderWidth={16}
         />

         <DecorativeEmptyCircle
            size={100}
            opacity={0.4}
            top={220}
            right={145}
            color="#A6B3BB"
            borderWidth={20}
         />
      </>
   )
}
