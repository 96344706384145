import './UserProfileForm.css'
import {
    Autocomplete,
    Button,
    Loader,
    PasswordInput,
    Select,
    TextInput,
    Textarea,
    Grid,
    NumberInput,
    Tooltip
} from "@mantine/core";
import {COUNTRY_LIST} from '../../../utils/country-list-registerPage';
import {DateInput} from '@mantine/dates';
import {useEffect, useState} from 'react';
import {GENDER_LIST} from '../../../utils/gender-list';
import PhoneInputField from '../../../components/PhoneInputField/PhoneInputField';
import moment from 'moment';
import useProfileForm from '../../../hooks/useProfileForm';
import useOnboarding from '../../../hooks/useOnboarding';
import {VALIDATIONS} from '../../../utils/validations-functions';
import {TOUR_STEPS} from '../../../utils/tour';

import SelectPreferedPet from '../../RegisterPage/SelectPreferedPet/SelectPreferedPet';
import {useNavigate} from 'react-router-dom';
import {USERS_TYPES} from "../../../utils/users-types-strings";
import PasswordChecker from "../../../components/PasswordChecker/PasswordChecker";
import OnBoardingBtn from '../../../components/OnBoardingBtn/OnBoardingBtn';

export default function UserProfileForm({currentUser = null}) {
    const navigate = useNavigate()
    const [displayChangePassword, setDisplayChangePassword] = useState(false);

    let initialFormValues = {
        profilePicture: currentUser.profilePicture,
        name: currentUser.name ?? '',
        lastname: currentUser.lastname ?? '',
        phone: currentUser.phone ?? '',
        email: currentUser.email ?? '',
        dni: currentUser.dni ?? '',
        nationality: currentUser.nationality ?? '',
        gender: currentUser.gender ?? '',
        city: currentUser.city ?? '',
        animalType: currentUser.animalType ?? '',
        birthDate: currentUser.birthDate ? moment(currentUser.birthDate, 'YYYY-MM-DDTHH:mm:ssZ').toDate() : '',
        description: currentUser?.about?.description ?? '',
        firstPassword: '',
        confirmPassword: '',
    }

    let formValidations = {
        name: VALIDATIONS.name,
        lastname: VALIDATIONS.lastname,
        phone: VALIDATIONS.phone,
        nationality: VALIDATIONS.nationality,
        birthDate: VALIDATIONS.birthDate,
        gender: VALIDATIONS.gender,
        city: VALIDATIONS.city,
        description: VALIDATIONS.description,
        firstPassword: VALIDATIONS.firstPassword,
        confirmPassword: VALIDATIONS.confirmPassword,
        dni: VALIDATIONS.dni
    }

    const {
        form,
        profilePicture,
        handleFileChange,
        handleSubmit,
        state,
        animalType,
        setAnimalType
    } = useProfileForm(initialFormValues, formValidations)

    const [dateValue, setDateValue] = useState(form?.value?.birthDate ? moment(form.value.birthDate, 'YYYY-MM-DD').toDate() : "");

    useEffect(() => {
        if (form.values.birthDate)
            setDateValue(moment(form.values.birthDate, 'YYYY-MM-DD').toDate());
    }, [form.values.birthDate]);

    const [runOnboarding, setRunOnboarding] = useState(false)
    const tour = useOnboarding({
        page: 'profile',
        steps: TOUR_STEPS.profile,
        disabled: state.isLoading || state.error,
        runAgain: runOnboarding,
        lastBtnFunction: () => setRunOnboarding(false)

    })

    return (
        <>
            <OnBoardingBtn onClick={() => setRunOnboarding(true)}/>

            <form className='UserProfile-form-container'>
                {tour}
                <div className='RegisterPage-avatar-group'>

                    <div className='RegisterPage-avatar-container'>
                        < img className='RegisterPage-avatar-image' src={profilePicture}
                              alt='avatar del usuario Nombre'/>
                    </div>

                    <div className='RegisterPage-avatar-userInfo-container'>
                        <h4 className='RegisterPage-avatar-userInfo--completeName'>{currentUser.name} {currentUser.lastname}</h4>
                        {currentUser.type &&
                            <h5 className='RegisterPage-avatar-userInfo--type'>{USERS_TYPES[currentUser.type]}</h5>}
                    </div>

                    < input className='RegisterPage-avatar--input' type='file' accept="image/png,image/jpeg"
                            onChange={handleFileChange}/>

                </div>

                <Grid style={{width: "100%"}}>
                    <Grid.Col span={6}>
                        <TextInput
                            label="Nombre:"
                            placeholder="Nombre"
                            {...form.getInputProps("name")}
                            w={'100%'}
                            autoComplete='off'
                        />
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <TextInput
                            label="Apellido:"
                            placeholder="Apellido"
                            {...form.getInputProps("lastname")}
                            w={'100%'}
                            autoComplete='off'
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <TextInput
                            label="Email:"
                            placeholder='Email'
                            {...form.getInputProps("email")}
                            w={'100%'}
                            disabled
                            autoComplete='off'
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <Tooltip
                            label="Debes completar con tu Documento de Identidad o Cédula de Identidad, Tarjeta de identidad - DNI - CI - TI - CC - CI">
                            <NumberInput
                                label='Número de identificación'
                                placeholder='Documento de Identidad o Cédula de Identidad'
                                {...form.getInputProps('dni')}
                                w={'100%'}
                                autoComplete='off'
                            />
                        </Tooltip>
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <PhoneInputField
                            name="phone"
                            fieldError={form.errors.phone}
                            {...form.getInputProps("phone")}
                            autoComplete='off'
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <Autocomplete
                            data={COUNTRY_LIST}
                            label='Nacionalidad'
                            placeholder='Argentina'
                            {...form.getInputProps('nationality')}
                            w={'100%'}
                            id='UserProfileForm-nationality'
                            autoComplete='off'
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <TextInput
                            label='Ciudad'
                            placeholder='Córdoba'
                            {...form.getInputProps('city')}
                            w={'100%'}
                            autoComplete='off'
                            id='UserProfileForm-city'
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <Select
                            label='Género'
                            id='UserProfileForm-gender'
                            data={GENDER_LIST}
                            {...form.getInputProps('gender')}
                            autoComplete='off'
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <DateInput
                            label="Fecha de nacimiento"
                            dateParser={(dateString) => moment(dateString, 'DD/MM/YYYY').toDate()}
                            defaultLevel="decade"
                            allowFreeInput
                            locale="es"
                            placeholder="dd/mm/yyyy"
                            inputFormat="DD/MM/YYYY"
                            valueFormat="DD/MM/YYYY"
                            value={dateValue}
                            onChange={(e) => setDateValue(moment(e, 'DD/MM/YYYY').toDate())}
                            onBlur={() => {
                                form.setFieldValue('birthDate', dateValue)
                            }}
                            autoComplete='off'
                            id='UserProfileForm-birthDate'
                            error={form?.errors?.birthDate}
                        />
                    </Grid.Col>

                    <Grid.Col span={12}>
                        <Textarea
                            label='Descripcion profesional:'
                            minRows={3}
                            maxRows={3}
                            autosize
                            id='UserProfileForm-description'
                            placeholder='Licenciado de la Universidad Nacional de Córdoba con más de 10 años de experiencia.'
                            autoComplete='off'
                            {...form.getInputProps('description')}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <SelectPreferedPet setFieldValue={form.setFieldValue} animalType={animalType}
                                           setAnimalType={setAnimalType}/>
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <p className='UserProfileForm-changePass'
                           onClick={() => setDisplayChangePassword(!displayChangePassword)}>
                            Cambiar contraseña
                        </p>
                    </Grid.Col>
                    {displayChangePassword &&
                        <>
                            <Grid.Col span={6}>
                                <PasswordInput
                                    label='Ingresá tu nueva contraseña:'
                                    autoComplete='off'
                                    classNames={{input: 'RegisterPageForm-password--input'}}
                                    placeholder='' {...form.getInputProps('firstPassword')} />
                            </Grid.Col>
                            <Grid.Col span={6}>
                                <PasswordInput
                                    autoComplete='off'
                                    label='Repetí tu contraseña:'
                                    classNames={{input: 'RegisterPageForm-password--input'}}
                                    placeholder='' {...form.getInputProps('confirmPassword')} />
                            </Grid.Col>
                            <Grid.Col span={12}>
                                <PasswordChecker password={form.values.firstPassword}/>
                            </Grid.Col>
                        </>
                    }
                    <Grid.Col span={12}>
                        {state.error &&
                            <p style={{
                                fontSize: 'var(--fs-body)',
                                color: 'var(--color-red)',
                                textAlign: 'center'
                            }}>{state.error}</p>}
                    </Grid.Col>

                    <Grid.Col span={12}>
                        <div className="UserProfileForm-rowInputGroup">
                            <Button disabled={state.isLoading}
                                    onClick={() => navigate(-1)} w={{base: "100%", lg: "50%"}}
                                    color={'var(--color-tertiary-light)'}
                                    variant='outline'>
                                {state.isLoading ? < Loader color={'#fff'} size={20}/> : 'Cancelar'}
                            </Button>
                            <Button disabled={state.isLoading} onClick={(event) => handleSubmit(event)}
                                    bg={'var(--color-tertiary-light)'}
                                    w={{base: "100%", lg: "50%"}}>
                                {state.isLoading ? < Loader color={'#fff'} size={20}/> : 'Guardar cambios'}
                            </Button>
                        </div>
                    </Grid.Col>
                </Grid>
            </form>
        </>
    )
}
