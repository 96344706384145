import { Flex, Skeleton, Stack } from '@mantine/core'
import React from 'react'

export default function LoginMegaMenuSkeleton() {
   return (
      <>
      <div
      style={{marginRight: 40}}
      >
         <div className='LoginMegaMenu--cardSlide' style={{ backgroundColor: '#ffffff50' }}>
            <div className='LoginMegaMenu-cardSlide--title-wrapper'>
               <Stack p={0} gap={6}>
                  <Skeleton h={20} w='275px' animate />
                  <Skeleton h={20} w='250px' animate />
                  <Skeleton h={20} w='200px' animate />
               </Stack>

            </div>
            <div className='LoginMegaMenu-cardSlide--content'>
               <Flex align='start' gap={4}>
                  <Skeleton h={24} w='24px' animate />
                  <Stack gap={4} align='flex-start'>
                     <Skeleton h={20} w={106} animate />
                     <Skeleton h={20} w={135} animate />
                  </Stack>
               </Flex>

               <Flex align='start' gap={4}>
                  <Skeleton h={24} w='24px' animate />
                  <Stack gap={4} align='flex-start'>
                     <Skeleton h={20} w={106} animate />
                     <Skeleton h={20} w={135} animate />
                  </Stack>
               </Flex>

               <Flex align='start' gap={4}>
                  <Skeleton h={24} w='24px' animate />
                  <Stack gap={4} align='flex-start'>
                     <Skeleton h={20} w={106} animate />
                     <Skeleton h={20} w={135} animate />
                  </Stack>
               </Flex>

               <Flex align='start' gap={4}>
                  <Skeleton h={24} w='24px' animate />
                  <Stack gap={4} align='flex-start'>
                     <Skeleton h={20} w={106} animate />
                     <Skeleton h={20} w={135} animate />
                  </Stack>
               </Flex>

               <Stack gap={4} align='flex-start' w='100%'>
                  <Skeleton h={22} w={106} animate />
                  <Skeleton h={38} w={'79%'} animate />
               </Stack>
            </div>
            <div className='LoginMegaMenu-button-container'>
               <Skeleton animate h={34} w='100%' />
            </div>
         </div>
      </div>

      <div
      >
         <div className='LoginMegaMenu--cardSlide' style={{ backgroundColor: '#fff' }}>
            <div className='LoginMegaMenu-cardSlide--title-wrapper'>
               <Stack p={0} gap={6}>
                  <Skeleton h={20} w='275px' animate />
                  <Skeleton h={20} w='200px' animate />
               </Stack>

            </div>
            <div className='LoginMegaMenu-cardSlide--content'>
               <Flex align='start' gap={4}>
                  <Skeleton h={24} w='24px' animate />
                  <Stack gap={4} align='flex-start'>
                     <Skeleton h={20} w={106} animate />
                     <Skeleton h={20} w={135} animate />
                  </Stack>
               </Flex>

               <Flex align='start' gap={4}>
                  <Skeleton h={24} w='24px' animate />
                  <Stack gap={4} align='flex-start'>
                     <Skeleton h={20} w={106} animate />
                     <Skeleton h={20} w={135} animate />
                  </Stack>
               </Flex>

               <Flex align='start' gap={4}>
                  <Skeleton h={24} w='24px' animate />
                  <Stack gap={4} align='flex-start'>
                     <Skeleton h={20} w={106} animate />
                     <Skeleton h={20} w={135} animate />
                  </Stack>
               </Flex>

               <Flex align='start' gap={4}>
                  <Skeleton h={24} w='24px' animate />
                  <Stack gap={4} align='flex-start'>
                     <Skeleton h={20} w={106} animate />
                     <Skeleton h={20} w={135} animate />
                  </Stack>
               </Flex>

               <Stack gap={4} align='flex-start' w='100%'>
                  <Skeleton h={22} w={106} animate />
                  <Skeleton h={38} w={'79%'} animate />
               </Stack>
            </div>
            <div className='LoginMegaMenu-button-container'>
               <Skeleton animate h={34} w='100%' />
            </div>
         </div>
      </div>
      </>
   )
}
